const countriesList = [
  //   { label: "Afghanistan", value: "Afghanistan" },
  //   { label: "Albania", value: "Albania" },
  //   { label: "Algeria", value: "Algeria" },
  //   { label: "American Samoa", value: "American Samoa" },
  //   { label: "Andorra", value: "Andorra" },
  //   { label: "Angola", value: "Angola" },
  //   { label: "Anguilla", value: "Anguilla" },
  //   { label: "Antarctica", value: "Antarctica" },
  //   { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  //   { label: "Argentina", value: "Argentina" },
  //   { label: "Armenia", value: "Armenia" },
  //   { label: "Aruba", value: "Aruba" },
  //   { label: "Australia", value: "Australia" },
  //   { label: "Austria", value: "Austria" },
  //   { label: "Azerbaijan", value: "Azerbaijan" },
  //   { label: "Bahamas", value: "Bahamas" },
  //   { label: "Bahrain", value: "Bahrain" },
  //   { label: "Bangladesh", value: "Bangladesh" },
  //   { label: "Barbados", value: "Barbados" },
  //   { label: "Belarus", value: "Belarus" },
  //   { label: "Belgium", value: "Belgium" },
  //   { label: "Belize", value: "Belize" },
  //   { label: "Benin", value: "Benin" },
  //   { label: "Bermuda", value: "Bermuda" },
  //   { label: "Bhutan", value: "Bhutan" },
  //   { label: "Bolivia", value: "Bolivia" },
  //   { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  //   { label: "Botswana", value: "Botswana" },
  //   { label: "Bouvet Island", value: "Bouvet Island" },
  //   { label: "Brazil", value: "Brazil" },
  //   {
  //     label: "British Antarctic Territory",
  //     value: "British Antarctic Territory",
  //   },
  //   {
  //     label: "British Indian Ocean Territory",
  //     value: "British Indian Ocean Territory",
  //   },
  //   { label: "British Virgin Islands", value: "British Virgin Islands" },
  //   { label: "Brunei", value: "Brunei" },
  //   { label: "Bulgaria", value: "Bulgaria" },
  //   { label: "Burkina Faso", value: "Burkina Faso" },
  //   { label: "Burundi", value: "Burundi" },
  //   { label: "Cambodia", value: "Cambodia" },
  //   { label: "Cameroon", value: "Cameroon" },
  //   { label: "Canada", value: "Canada" },
  //   {
  //     label: "Canton and Enderbury Islands",
  //     value: "Canton and Enderbury Islands",
  //   },
  //   { label: "Cape Verde", value: "Cape Verde" },
  //   { label: "Cayman Islands", value: "Cayman Islands" },
  //   { label: "Central African Republic", value: "Central African Republic" },
  //   { label: "Chad", value: "Chad" },
  //   { label: "Chile", value: "Chile" },
  //   { label: "China", value: "China" },
  //   { label: "Christmas Island", value: "Christmas Island" },
  //   { label: "Cocos [Keeling] Islands", value: "Cocos [Keeling] Islands" },
  //   { label: "Colombia", value: "Colombia" },
  //   { label: "Comoros", value: "Comoros" },
  //   { label: "Congo - Brazzaville", value: "Congo - Brazzaville" },
  //   { label: "Congo - Kinshasa", value: "Congo - Kinshasa" },
  //   { label: "Cook Islands", value: "Cook Islands" },
  //   { label: "Costa Rica", value: "Costa Rica" },
  //   { label: "Croatia", value: "Croatia" },
  //   { label: "Cuba", value: "Cuba" },
  //   { label: "Cyprus", value: "Cyprus" },
  //   { label: "Czech Republic", value: "Czech Republic" },
  //   { label: "Côte d’Ivoire", value: "Côte d’Ivoire" },
  //   { label: "Denmark", value: "Denmark" },
  //   { label: "Djibouti", value: "Djibouti" },
  //   { label: "Dominica", value: "Dominica" },
  //   { label: "Dominican Republic", value: "Dominican Republic" },
  //   { label: "Dronning Maud Land", value: "Dronning Maud Land" },
  //   { label: "East Germany", value: "East Germany" },
  //   { label: "Ecuador", value: "Ecuador" },
  //   { label: "Egypt", value: "Egypt" },
  //   { label: "El Salvador", value: "El Salvador" },
  //   { label: "Equatorial Guinea", value: "Equatorial Guinea" },
  //   { label: "Eritrea", value: "Eritrea" },
  //   { label: "Estonia", value: "Estonia" },
  //   { label: "Ethiopia", value: "Ethiopia" },
  //   { label: "Falkland Islands", value: "Falkland Islands" },
  //   { label: "Faroe Islands", value: "Faroe Islands" },
  //   { label: "Fiji", value: "Fiji" },
  //   { label: "Finland", value: "Finland" },
  //   { label: "France", value: "France" },
  //   { label: "French Guiana", value: "French Guiana" },
  //   { label: "French Polynesia", value: "French Polynesia" },
  //   {
  //     label: "French Southern Territories",
  //     value: "French Southern Territories",
  //   },
  //   {
  //     label: "French Southern and Antarctic Territories",
  //     value: "French Southern and Antarctic Territories",
  //   },
  //   { label: "Gabon", value: "Gabon" },
  //   { label: "Gambia", value: "Gambia" },
  //   { label: "Georgia", value: "Georgia" },
  //   { label: "Germany", value: "Germany" },
  //   { label: "Ghana", value: "Ghana" },
  //   { label: "Gibraltar", value: "Gibraltar" },
  //   { label: "Greece", value: "Greece" },
  //   { label: "Greenland", value: "Greenland" },
  //   { label: "Grenada", value: "Grenada" },
  //   { label: "Guadeloupe", value: "Guadeloupe" },
  //   { label: "Guam", value: "Guam" },
  //   { label: "Guatemala", value: "Guatemala" },
  //   { label: "Guernsey", value: "Guernsey" },
  //   { label: "Guinea", value: "Guinea" },
  //   { label: "Guinea-Bissau", value: "Guinea-Bissau" },
  //   { label: "Guyana", value: "Guyana" },
  //   { label: "Haiti", value: "Haiti" },
  //   {
  //     label: "Heard Island and McDonald Islands",
  //     value: "Heard Island and McDonald Islands",
  //   },
  //   { label: "Honduras", value: "Honduras" },
  //   { label: "Hong Kong SAR China", value: "Hong Kong SAR China" },
  //   { label: "Hungary", value: "Hungary" },
  //   { label: "Iceland", value: "Iceland" },
  //   { label: "India", value: "India" },
  //   { label: "Indonesia", value: "Indonesia" },
  //   { label: "Iran", value: "Iran" },
  //   { label: "Iraq", value: "Iraq" },
  //   { label: "Ireland", value: "Ireland" },
  //   { label: "Isle of Man", value: "Isle of Man" },
  //   { label: "Israel", value: "Israel" },
  //   { label: "Italy", value: "Italy" },
  //   { label: "Jamaica", value: "Jamaica" },
  //   { label: "Japan", value: "Japan" },
  //   { label: "Jersey", value: "Jersey" },
  //   { label: "Johnston Island", value: "Johnston Island" },
  //   { label: "Jordan", value: "Jordan" },
  //   { label: "Kazakhstan", value: "Kazakhstan" },
  //   { label: "Kenya", value: "Kenya" },
  //   { label: "Kiribati", value: "Kiribati" },
  //   { label: "Kuwait", value: "Kuwait" },
  //   { label: "Kyrgyzstan", value: "Kyrgyzstan" },
  //   { label: "Laos", value: "Laos" },
  //   { label: "Latvia", value: "Latvia" },
  //   { label: "Lebanon", value: "Lebanon" },
  //   { label: "Lesotho", value: "Lesotho" },
  //   { label: "Liberia", value: "Liberia" },
  //   { label: "Libya", value: "Libya" },
  //   { label: "Liechtenstein", value: "Liechtenstein" },
  //   { label: "Lithuania", value: "Lithuania" },
  //   { label: "Luxembourg", value: "Luxembourg" },
  //   { label: "Macau SAR China", value: "Macau SAR China" },
  //   { label: "Macedonia", value: "Macedonia" },
  //   { label: "Madagascar", value: "Madagascar" },
  //   { label: "Malawi", value: "Malawi" },
  //   { label: "Malaysia", value: "Malaysia" },
  //   { label: "Maldives", value: "Maldives" },
  //   { label: "Mali", value: "Mali" },
  //   { label: "Malta", value: "Malta" },
  //   { label: "Marshall Islands", value: "Marshall Islands" },
  //   { label: "Martinique", value: "Martinique" },
  //   { label: "Mauritania", value: "Mauritania" },
  //   { label: "Mauritius", value: "Mauritius" },
  //   { label: "Mayotte", value: "Mayotte" },
  //   { label: "Metropolitan France", value: "Metropolitan France" },
  //   { label: "Mexico", value: "Mexico" },
  //   { label: "Micronesia", value: "Micronesia" },
  //   { label: "Midway Islands", value: "Midway Islands" },
  //   { label: "Moldova", value: "Moldova" },
  //   { label: "Monaco", value: "Monaco" },
  //   { label: "Mongolia", value: "Mongolia" },
  //   { label: "Montenegro", value: "Montenegro" },
  //   { label: "Montserrat", value: "Montserrat" },
  //   { label: "Morocco", value: "Morocco" },
  //   { label: "Mozambique", value: "Mozambique" },
  //   { label: "Myanmar [Burma]", value: "Myanmar [Burma]" },
  //   { label: "Namibia", value: "Namibia" },
  //   { label: "Nauru", value: "Nauru" },
  //   { label: "Nepal", value: "Nepal" },
  //   { label: "Netherlands", value: "Netherlands" },
  //   { label: "Netherlands Antilles", value: "Netherlands Antilles" },
  //   { label: "Neutral Zone", value: "Neutral Zone" },
  //   { label: "New Caledonia", value: "New Caledonia" },
  //   { label: "New Zealand", value: "New Zealand" },
  //   { label: "Nicaragua", value: "Nicaragua" },
  //   { label: "Niger", value: "Niger" },
  //   { label: "Nigeria", value: "Nigeria" },
  //   { label: "Niue", value: "Niue" },
  //   { label: "Norfolk Island", value: "Norfolk Island" },
  //   { label: "North Korea", value: "North Korea" },
  //   { label: "North Vietnam", value: "North Vietnam" },
  //   { label: "Northern Mariana Islands", value: "Northern Mariana Islands" },
  //   { label: "Norway", value: "Norway" },
  //   { label: "Oman", value: "Oman" },
  //   {
  //     label: "Pacific Islands Trust Territory",
  //     value: "Pacific Islands Trust Territory",
  //   },
  //   { label: "Pakistan", value: "Pakistan" },
  //   { label: "Palau", value: "Palau" },
  //   { label: "Palestinian Territories", value: "Palestinian Territories" },
  //   { label: "Panama", value: "Panama" },
  //   { label: "Panama Canal Zone", value: "Panama Canal Zone" },
  //   { label: "Papua New Guinea", value: "Papua New Guinea" },
  //   { label: "Paraguay", value: "Paraguay" },
  //   {
  //     label: "People's Democratic Republic of Yemen",
  //     value: "People's Democratic Republic of Yemen",
  //   },
  //   { label: "Peru", value: "Peru" },
  //   { label: "Philippines", value: "Philippines" },
  //   { label: "Pitcairn Islands", value: "Pitcairn Islands" },
  //   { label: "Poland", value: "Poland" },
  //   { label: "Portugal", value: "Portugal" },
  //   { label: "Puerto Rico", value: "Puerto Rico" },
  //   { label: "Qatar", value: "Qatar" },
  //   { label: "Romania", value: "Romania" },
  //   { label: "Russia", value: "Russia" },
  //   { label: "Rwanda", value: "Rwanda" },
  //   { label: "Réunion", value: "Réunion" },
  //   { label: "Saint Barthélemy", value: "Saint Barthélemy" },
  //   { label: "Saint Helena", value: "Saint Helena" },
  //   { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  //   { label: "Saint Lucia", value: "Saint Lucia" },
  //   { label: "Saint Martin", value: "Saint Martin" },
  //   { label: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
  //   {
  //     label: "Saint Vincent and the Grenadines",
  //     value: "Saint Vincent and the Grenadines",
  //   },
  //   { label: "Samoa", value: "Samoa" },
  //   { label: "San Marino", value: "San Marino" },
  //   { label: "Saudi Arabia", value: "Saudi Arabia" },
  //   { label: "Senegal", value: "Senegal" },
  //   { label: "Serbia", value: "Serbia" },
  //   { label: "Serbia and Montenegro", value: "Serbia and Montenegro" },
  //   { label: "Seychelles", value: "Seychelles" },
  //   { label: "Sierra Leone", value: "Sierra Leone" },
  //   { label: "Singapore", value: "Singapore" },
  //   { label: "Slovakia", value: "Slovakia" },
  //   { label: "Slovenia", value: "Slovenia" },
  //   { label: "Solomon Islands", value: "Solomon Islands" },
  //   { label: "Somalia", value: "Somalia" },
  //   { label: "South Africa", value: "South Africa" },
  //   {
  //     label: "South Georgia and the South Sandwich Islands",
  //     value: "South Georgia and the South Sandwich Islands",
  //   },
  //   { label: "South Korea", value: "South Korea" },
  //   { label: "Spain", value: "Spain" },
  //   { label: "Sri Lanka", value: "Sri Lanka" },
  //   { label: "Sudan", value: "Sudan" },
  //   { label: "Suriname", value: "Suriname" },
  //   { label: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
  //   { label: "Swaziland", value: "Swaziland" },
  //   { label: "Sweden", value: "Sweden" },
  //   { label: "Switzerland", value: "Switzerland" },
  //   { label: "Syria", value: "Syria" },
  //   { label: "São Tomé and Príncipe", value: "São Tomé and Príncipe" },
  //   { label: "Taiwan", value: "Taiwan" },
  //   { label: "Tajikistan", value: "Tajikistan" },
  //   { label: "Tanzania", value: "Tanzania" },
  //   { label: "Thailand", value: "Thailand" },
  //   { label: "Timor-Leste", value: "Timor-Leste" },
  //   { label: "Togo", value: "Togo" },
  //   { label: "Tokelau", value: "Tokelau" },
  //   { label: "Tonga", value: "Tonga" },
  //   { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  //   { label: "Tunisia", value: "Tunisia" },
  //   { label: "Turkey", value: "Turkey" },
  //   { label: "Turkmenistan", value: "Turkmenistan" },
  //   { label: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
  //   { label: "Tuvalu", value: "Tuvalu" },
  //   {
  //     label: "U.S. Minor Outlying Islands",
  //     value: "U.S. Minor Outlying Islands",
  //   },
  //   {
  //     label: "U.S. Miscellaneous Pacific Islands",
  //     value: "U.S. Miscellaneous Pacific Islands",
  //   },
  //   { label: "U.S. Virgin Islands", value: "U.S. Virgin Islands" },
  //   { label: "Uganda", value: "Uganda" },
  //   { label: "Ukraine", value: "Ukraine" },
  //   {
  //     label: "Union of Soviet Socialist Republics",
  //     value: "Union of Soviet Socialist Republics",
  //   },
  //   { label: "United Arab Emirates", value: "United Arab Emirates" },
  //   { label: "United Kingdom", value: "United Kingdom" },
  { label: "United States", value: "United States" },
  //   { label: "Unknown or Invalid Region", value: "Unknown or Invalid Region" },
  //   { label: "Uruguay", value: "Uruguay" },
  //   { label: "Uzbekistan", value: "Uzbekistan" },
  //   { label: "Vanuatu", value: "Vanuatu" },
  //   { label: "Vatican City", value: "Vatican City" },
  //   { label: "Venezuela", value: "Venezuela" },
  //   { label: "Vietnam", value: "Vietnam" },
  //   { label: "Wake Island", value: "Wake Island" },
  //   { label: "Wallis and Futuna", value: "Wallis and Futuna" },
  //   { label: "Western Sahara", value: "Western Sahara" },
  //   { label: "Yemen", value: "Yemen" },
  //   { label: "Zambia", value: "Zambia" },
  //   { label: "Zimbabwe", value: "Zimbabwe" },
  //   { label: "Åland Islands", value: "Åland Islands" },
];

const usaStatesList = [
  { value: "Unknown", label: "Unknown" },
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
];
export { usaStatesList, countriesList };
