import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { constants } from "src/constant/constants";
import { deleteRequest, getRequest, postRequest, putRequest } from "src/services/helper";
import {
  CForm,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CRow,
  CCol,
  CModalFooter,
  CFormInput,
  CFormLabel,
  CFormCheck,
  CFormSelect,
  CFormFeedback,
  CInputGroup,
  CInputGroupText,
  CFormTextarea,
  CAlert,
  CToast,
  CToastBody,
  CToastClose,
  CToaster,
} from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import ReactSignatureCanvas from "react-signature-canvas";
import { usaStatesList } from "../owners-list/data";
import moment from "moment";
const HorseRegister = ({
  modalState,
  setModalState,
  modalData,
  mode,
  fetchData,
}) => {
  const today = new Date().toISOString().split("T")[0];
  const formObject = {
    registrationType: '',
    registrationNum: "",
    // pedigree: "",
    verified: true,
    registrationDocument: null,
    performanceVerification:"",
    performanceDocument: null,
    performanceLink: "",
    stateFoaledIn: "",
    breed: "",
    foalDate: "",
    breedingDate: "",
    bredBy: "",
    firstChoice: "",
    secondChoice: "",
    thirdChoice: "",
    otherRegistries: [
      { registryName: "", registryNumber: "", registryPaperwork: null },
      { registryName: "", registryNumber: "", registryPaperwork: null },
      { registryName: "", registryNumber: "", registryPaperwork: null },
      { registryName: "", registryNumber: "", registryPaperwork: null },
    ],
    sex: "",
    color: "",
    pattern: "",
    DNA: "",
    DNA_CASE: "",
    DNA_TEST_DATE: "",
    dnaTestSubmission: "",
    sire_name: "",
    sire_reg: "",
    sire_other_reg_name: "",
    sire_other_reg_number: "",
    sire_paperwork: null,
    sire_grand_sire_name: "",
    sire_grand_sire_reg: "",
    sire_grand_sire_other_name: "",
    sire_grand_sire_other_number: "",
    sire_grand_sire_paperwork: null,
    sire_grand_dam_name: "",
    sire_grand_dam_reg: "",
    sire_grand_dam_other_name: "",
    sire_grand_dam_other_number: "",
    sire_grand_dam_paperwork: null,
    sire_great_grand_sire_name: "",
    sire_great_grand_sire_reg: "",
    sire_great_grand_sire_other_name: "",
    sire_great_grand_sire_other_number: "",
    sire_great_grand_sire_paperwork: null,
    sire_great_grand_dam_name: "",
    sire_great_grand_dam_reg: "",
    sire_great_grand_dam_other_name: "",
    sire_great_grand_dam_other_number: "",
    sire_great_grand_dam_paperwork: null,
    dam_name: "",
    dam_reg: "",
    dam_other_reg_name: "",
    dam_other_reg_number: "",
    dam_paperwork: "",
    dam_grand_sire_name: "",
    dam_grand_sire_reg: "",
    dam_grand_sire_other_name: "",
    dam_grand_sire_other_number: "",
    dam_grand_sire_paperwork: "",
    dam_grand_dam_name: "",
    dam_grand_dam_reg: "",
    dam_grand_dam_other_name: "",
    dam_grand_dam_other_number: "",
    dam_grand_dam_paperwork: "",
    dam_great_grand_sire_name: "",
    dam_great_grand_sire_reg: "",
    dam_great_grand_sire_other_name: "",
    dam_great_grand_sire_other_number: "",
    dam_great_grand_sire_paperwork: "",
    dam_great_grand_dam_name: "",
    dam_great_grand_dam_reg: "",
    dam_great_grand_dam_other_name: "",
    dam_great_grand_dam_other_number: "",
    dam_great_grand_dam_paperwork: "",
    othermarkings_left: "",
    othermarkings_right: "",
    othermarkings_face: "",
    othermarkings_chin: "",
    othermarkings_frontLeg: "",
    othermarkings_hindLeg: "",
    othermarkings_maneColor: "",
    othermarkings_tailColor: "",
    markingDocuments: [],
    ownerType: "",
    owners: [
      { memberNum: "", name: "", address: "", city: "", state: "", zip: "" },
    ],
    checkedOwnerInfo: false,
    ownerSignature: null,
    fees: { registrationFee: 30, prepayDNAFee: false, prepayDNAFeeAmount: 0, verification_fees: 0 },
    billingInfo: {
      billingAddress: "",
      phoneNumber: "",
      email: "",
      paymentInfo: {
        paymentMethod: "",
        creditCard: {
          cardType: "",
          cardNumber: "",
          expirationDate: "",
          cvv: "",
        },
        bankAccount: {
          accountType: "",
          accountName: "",
          bankName: "",
          routingNumber: "",
          accountNumber: "",
        },
      },
      accountHolderName: "",
      accountHolderSignature: null,
    },
    checkedAgreement: false,
    paymentMethodId: "",
  };

  const [Sire, setSire] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const ownerSignatureRef = React.useRef();
  const accountHolderSignRef = React.useRef();
  const [openOnwerSignature, setOpenOnwerSignature] = useState(false);
  const [openCheckoutPage, setCheckoutPage] = useState(false);
  const [openAccountHolderSign, setOpenAccountHolderSign] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const intervalRef = useRef(null);

  console.log("the openCheckoutPage is",openCheckoutPage)

  const clearSignature = (ref, keyItem) => {
    ref.current.clear();
    setValue(keyItem, null);
  };

  const saveSignature = (ref, keyItem) => {
    const signatureData = ref.current.getTrimmedCanvas().toDataURL("image/png");
    setValue(keyItem, base64ToBlob(signatureData, "image/png"));
    trigger(keyItem);
    ref.current.clear();
  };

  const base64ToBlob = (base64, contentType = "") => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }
    return new Blob(byteArrays, { type: contentType });
  };

  const stripe = useStripe();
  const elements = useElements();

  const createHorse = async (data) => {
    console.log("The data is", data);
    try {
      const res = await (!mode
        ? postRequest(
            `${constants.endPoints.ownerEndPoints.ownerRegisterHorse}`,
            data
          )
        : putRequest(
            `${constants.endPoints.ownerEndPoints.ownerEditHorse}/${modalData._id}`,
            data
          ));
      return res.data;
    } catch (e) {
      console.log(e);
      setErrMsg(toastMessage(e?.error, "danger"));
      setIsSubmitted(false)
    }
  };

  const createPaymentReq = async (data, id) => {
    try {
      const res = await putRequest(
        constants.endPoints.ownerEndPoints.ownerRegisterPayment + `/${id}`,
        data
      );
      return res.data;
    } catch (e) {
      return e;
    }
  };

  const getPaymentStatus = async (id) => {
    try {
      const res = await getRequest(
        constants.endPoints.ownerEndPoints.ownerGetPaymentStatus + `${id}`
      );
      return res.data;
    } catch (e) {
      return e;
    }
  };

  const deleteHorse = async (horseId) => {
    try {
      const res = await deleteRequest(
        `${constants.endPoints.ownerEndPoints.ownerDeleteHorse}/${horseId}`
      );
      return res.data;
    } catch (e) {
      setErrMsg(toastMessage(e?.error, "danger"));
      setIsSubmitted(false)
    }
  };

  const callPaymentAPI = async (paymentPayload, data) => {
    try {
      const res = await createPaymentReq(paymentPayload, data._id);
      console.log("createPaymentReq", res);

      if (res?.success) {
        setModalState(false);
        fetchData()
        setErrMsg(toastMessage(res?.message, "success"));
        setIsSubmitted(false);
        setCheckoutPage(false);
      } else {
        console.log('createPaymentReq else',res?.url);
        if(res?.url){
          setErrMsg(toastMessage(res?.message, "danger"));
          window.open(res?.url, "_blank");

          // Poll for payment status every second
          intervalRef.current = setInterval(async () => {
            try {
              const paymentStatus = await getPaymentStatus(res.paymentIntentId);
              console.log("Payment status:", paymentStatus);
              if(paymentStatus?.paymentStatus != "requires_action"){
                clearInterval(intervalRef.current);
                setErrMsg(toastMessage(paymentStatus?.paymentStatus == 'succeeded' ? 'Horse Register successfully' : 'Payment Failed!', paymentStatus?.paymentStatus == 'succeeded' ? 'success': 'danger'));
                setModalState(false);
                fetchData();
              }
            } catch (pollError) {
              console.error("Error polling payment status:", pollError);
              clearInterval(intervalRef.current); // Stop polling on error
              setErrMsg(toastMessage("Error checking payment status.", "danger"));
              setIsSubmitted(false);
            }
          }, 5000); // Poll every 5 second
  
          // Stop polling after 5 minutes (300000 milliseconds)
          setTimeout(() => {
            setErrMsg(toastMessage("Payment status is in progress", "warning"));
            setIsSubmitted(false);
            clearInterval(intervalRef.current);
            setModalState(false);
            fetchData()
            console.log("Stopped polling after 5 minutes");
          }, 300000);
        }else{
          setModalState(false);
          fetchData()
          setErrMsg(toastMessage(res?.message, "danger"));
          setIsSubmitted(false);
        }
      }
    } catch (error) {
      console.error("Payment API Error:", error);
      setModalState(false);
      fetchData()
      setErrMsg(toastMessage("Payment process failed", "danger"));
    }
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        console.log('fffff');
        
        clearInterval(intervalRef.current);
        console.log("Interval cleared on unmount.");
      }
    };
  }, []);

  const paymentInfoFun = async (data) => {

    if (data?.billingInfo?.paymentInfo?.paymentMethod === "Credit Card") {
      // For Credit Card Payment Method
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        setErrMsg(toastMessage(error?.message, "danger"));
        deleteHorse(data?._id);
        setIsSubmitted(false);
      } else {
        setValue("paymentMethodId", paymentMethod.id);

        const paymentPayload = new FormData();
        paymentPayload.append("paymentMethodId", paymentMethod.id);
        paymentPayload.append("billingInfo", JSON.stringify(data.billingInfo));
        paymentPayload.append("fees", JSON.stringify(data.fees));

        callPaymentAPI(paymentPayload, data);
      }
    } else if (data?.billingInfo?.paymentInfo?.paymentMethod === "Bank") {
      // For Bank Payment Method (ACH)
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "us_bank_account",
        us_bank_account: {
          account_number:
            data.billingInfo.paymentInfo.bankAccount.accountNumber,
          routing_number:
            data.billingInfo.paymentInfo.bankAccount.routingNumber,
          account_holder_type: "individual",
        },
        billing_details: {
          name: "sasas",
        },
      });

      if (error) {
        setErrMsg(toastMessage(error?.message, "danger"));
        deleteHorse(data?._id)
        setIsSubmitted(false);
      } else {
        setValue("paymentMethodId", paymentMethod.id);
        const paymentPayload = new FormData();
        paymentPayload.append("paymentMethodId", paymentMethod.id);
        paymentPayload.append("billingInfo", JSON.stringify(data.billingInfo));
        paymentPayload.append("fees", JSON.stringify(data.fees));

        callPaymentAPI(paymentPayload, data);
      }
    }
  };

  const handleFileExtensionCheck = (files) => {
    const file = files?.[0];
    if (typeof files === "string") {
      return true;
    }
    if (!file) {
      return;
    }

    const allowedFormats = [".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png"];
    const fileExtension = file?.name?.split(".")?.pop();
    if (!allowedFormats.includes("." + fileExtension?.toLowerCase())) {
      return false;
    }
    return true;
  };
  const handleFileSizeCheck = (files) => {
    const file = files?.[0];
    if (typeof files === "string") {
      return true;
    }
    if (!file) {
      return;
    }
    const maxSize = 10 * 1024 * 1024;
    if (file.size > maxSize) {
      return false;
    }
    return true;
  };

  const otherRegistrySchema = Yup.object().shape({
    registryName: Yup.string()
      .trim("Space are not allowed")
      .max(50, "Registry name must be less than 10 characters"),
    registryNumber: Yup.string()
      .trim("Space are not allowed")
      .max(30, "Registry number must be less than 10 characters"),
    registryPaperwork: Yup.mixed()
      .nullable()
      .test(
        "fileFormat",
        "Invalid file format. Please upload a valid file type",
        (value) => {
          // Only perform the check if a file is uploaded
          if (value) {
            return handleFileExtensionCheck(value);
          }
          return true;
        }
      ),
  });

  const ownersSchema = Yup.object().shape({
    memberNum: Yup.string().trim().required().label("Member Number"),
    name: Yup.string().trim().required().label("Name"),
    address: Yup.string().trim().required().label("Address"),
    city: Yup.string().trim().required().label("City"),
    state: Yup.string().trim().required().label("State"),
    zip: Yup.string().trim().required().label("Zip"),
  });

  const horseSchema = Yup.object().shape({
    registrationType: Yup.string().nullable(),
    registrationNum: Yup.string()
      .trim()
      // .required()
      // .matches(/^[a-zA-Z0-9]+$/, "Registration Number cannot contain spaces")
      .label("Registration Number"),
    // pedigree: Yup.string().trim().required().label("Pedigree"),
    verified: Yup.string().trim().required().label("Verified"),
    registrationDocument: Yup.mixed()
      .required("Horse Registration Document is required")
      .test(
        "",
        "Invalid file format. Please upload valid file type",
        handleFileExtensionCheck
      )
      .nullable()
      .test("", "Max size limit exceeded", handleFileSizeCheck),
    performanceDocument: Yup.mixed().nullable().optional(),
    // .test(
    //   "",
    //   "Invalid file format. Please upload valid file type",
    //   handleFileExtensionCheck
    // )
    // .test("", "Max size limit exceeded", handleFileSizeCheck),
    performanceLink: Yup.string().nullable().trim().label("Performance Link"),
    performanceVerification: Yup.string().trim().required().label("Performance Verification"),
    stateFoaledIn: Yup.string().trim().required().label("State Foaled In"),
    breed: Yup.string().nullable().trim().label("Breed"),
    foalDate: Yup.string().nullable().trim().required().label("Foal Date"),
    breedingDate: Yup.string()
      .nullable()
      .trim()
      .required()
      .label("Breeding Date"),
    bredBy: Yup.string().nullable().trim().label("Breed By"),
    sex: Yup.string().trim().required().label("Sex"),
    otherRegistries: Yup.array().of(otherRegistrySchema).nullable(),
    color: Yup.string().trim().required().label("Color"),
    pattern: Yup.string().trim().required().label("Pattern"),
    DNA: Yup.string().trim().required("DNA is required field").label("DNA"),
    DNA_CASE: Yup.string().trim().label("DNA CASE"),
    DNA_TEST_DATE: Yup.string()
      .nullable()
      .trim()
      .when("DNA", (DNAValue, schema) => {
        if (DNAValue[0] === "Yes") {
          return schema
            .required("DNA Test date is required")
            .label("DNA Test Date");
        } else {
          return schema.label("DNA Test Date");
        }
      })
      .label("DNA Test Date"),
    dnaTestSubmission: Yup.string()
      .trim()
      .nullable()
      .label("DNA Test Submission"),

    sire_name: Yup.string().trim().label("Sire Name"),
    sire_reg: Yup.string().trim().label("Sire Register"),
    sire_other_reg_name: Yup.string().trim().label("Sire Other Registry Name"),
    sire_other_reg_number: Yup.string()
      .trim()
      .label("Sire Other Register Number"),
    sire_paperwork: Yup.mixed().nullable().label("Sire Paperwork"),
    sire_grand_sire_name: Yup.string().trim().label("Sire Grand (Sire) name"),
    sire_grand_sire_reg: Yup.string()
      .trim()
      .label("Sire Grand (Sire) Register"),
    sire_grand_sire_other_reg_name: Yup.string()
      .trim()
      .label("Sire Grand (Sire) Other Registry Name"),
    sire_grand_sire_other_reg_number: Yup.string()
      .trim()
      .label("Sire Grand (Sire) Other Register Number"),
    sire_grand_sire_paperwork: Yup.mixed()
      .nullable()
      .label("Sire Grand (Sire) Paperwork"),
    sire_grand_dam_name: Yup.string().trim().label("Dam Grand (Sire) Name"),
    sire_grand_dam_reg: Yup.string().trim().label("Dam Grand (Sire) Register"),
    sire_grand_dam_other_reg_name: Yup.string()
      .trim()
      .label("Dam Grand (Sire) Other Registry Name"),
    sire_grand_dam_other_reg_number: Yup.string()
      .trim()
      .label("Dam Grand (Sire) Other Register Number"),
    sire_grand_dam_paperwork: Yup.mixed()
      .nullable()
      .label("Dam Grand (Sire) Paperwork"),
    sire_great_grand_sire_name: Yup.string()
      .trim()
      .label("Great-Grand Sire (Sire) Name"),
    sire_great_grand_sire_reg: Yup.string()
      .trim()
      .label("Great-Grand Sire (Sire) Reg"),
    sire_great_grand_sire_other_name: Yup.string()
      .trim()
      .label("Great-Grand Sire (Sire) Other Registry Name"),
    sire_great_grand_sire_other_number: Yup.string()
      .trim()
      .label("Great-Grand Sire (Sire) Other Registry Number"),
    sire_great_grand_sire_paperwork: Yup.mixed()
      .nullable()
      .label("Great-Grand Sire (Sire) paperwork"),
    sire_great_grand_dam_name: Yup.string()
      .trim()
      .label("Great-Grand Dam (Sire) Name"),
    sire_great_grand_dam_reg: Yup.string()
      .trim()
      .label("Great-Grand Dam (Sire) Reg"),
    sire_great_grand_dam_other_name: Yup.string()
      .trim()
      .label("Great-Grand Dam (Sire) Other Registry Name"),
    sire_great_grand_dam_other_number: Yup.string()
      .trim()
      .label("Great-Grand Dam (Sire) Other Registry Number"),
    sire_great_grand_dam_paperwork: Yup.mixed()
      .nullable()
      .label("Great-Grand Dam (Sire) Paperwork"),

    dam_name: Yup.string().trim().label("Dam Name"),
    dam_reg: Yup.string().trim().label("Dam Register"),
    dam_other_reg_name: Yup.string().trim().label("Dam Other Registry Name"),
    dam_other_reg_number: Yup.string()
      .trim()
      .label("Dam Other Register Number"),
    dam_paperwork: Yup.mixed().nullable().label("Dam Paperwork"),
    dam_grand_sire_name: Yup.string().trim().label("Sire Grand (Dam) name"),
    dam_grand_sire_reg: Yup.string().trim().label("Sire Grand (Dam) Register"),
    dam_grand_sire_other_reg_name: Yup.string()
      .trim()
      .label("Sire Grand (Dam) Other Registry Name"),
    dam_grand_sire_other_reg_number: Yup.string()
      .trim()
      .label("Sire Grand (Dam) Other Register Number"),
    dam_grand_sire_paperwork: Yup.mixed()
      .nullable()
      .label("Sire Grand (Dam) Paperwork"),
    dam_grand_dam_name: Yup.string().trim().label("Dam Grand (Dam) Name"),
    dam_grand_dam_reg: Yup.string().trim().label("Dam Grand (Dam) Register"),
    dam_grand_dam_other_reg_name: Yup.string()
      .trim()
      .label("Dam Grand (Dam) Other Registry Name"),
    dam_grand_dam_other_reg_number: Yup.string()
      .trim()
      .label("Dam Grand (Dam) Other Register Number"),
    dam_grand_dam_paperwork: Yup.mixed()
      .nullable()
      .label("Dam Grand (Dam) Paperwork"),
    dam_great_grand_sire_name: Yup.string()
      .trim()
      .label("Great-Grand Sire (Dam) Name"),
    dam_great_grand_sire_reg: Yup.string()
      .trim()
      .label("Great-Grand Sire (Dam) Reg"),
    dam_great_grand_sire_other_name: Yup.string()
      .trim()
      .label("Great-Grand Sire (Dam) Other Registry Name"),
    dam_great_grand_sire_other_number: Yup.string()
      .trim()
      .label("Great-Grand Sire (Dam) Other Registry Number"),
    dam_great_grand_sire_paperwork: Yup.mixed()
      .nullable()
      .label("Great-Grand Sire (Dam) paperwork"),
    dam_great_grand_dam_name: Yup.string()
      .trim()
      .label("Great-Grand Dam (Dam) Name"),
    dam_great_grand_dam_reg: Yup.string()
      .trim()
      .label("Great-Grand Dam (Dam) Reg"),
    dam_great_grand_dam_other_name: Yup.string()
      .trim()
      .label("Great-Grand Dam (Dam) Other Registry Name"),
    dam_great_grand_dam_other_number: Yup.string()
      .trim()
      .label("Great-Grand Dam (Dam) Other Registry Number"),
    dam_great_grand_dam_paperwork: Yup.mixed()
      .nullable()
      .label("Great-Grand Dam (Dam) Paperwork"),

    othermarkings_left: Yup.string().trim().label("Left Marking"),
    othermarkings_right: Yup.string().trim().label("Right Marking"),
    othermarkings_face: Yup.string().trim().label("Face Marking"),
    othermarkings_chin: Yup.string().trim().label("Chin Marking"),
    othermarkings_FrontLeg: Yup.string().trim().label("Front leg Marking"),
    othermarkings_hindLeg: Yup.string().trim().label("Hind Leg Marking"),
    othermarkings_maneColor: Yup.string().trim().label("Mane Color Marking"),
    othermarkings_tailColor: Yup.string().trim().label("Tail Color Marking"),

    markingDocuments: Yup.mixed()
      .required("Horse Marking Document is required")
      .test(
        "file-count",
        "At least 4 files are required.",
        (value) => value && value.length >= 4
      )
      .test(
        "file-format",
        "Invalid file format. Please upload valid file type",
        handleFileExtensionCheck
      )
      .test("file-size", "Max size limit exceeded", handleFileSizeCheck),

    ownerType: Yup.string().trim().required().label("Owner Type"),
    owners: Yup.array().of(ownersSchema),
    checkedOwnerInfo: Yup.boolean()
      .required("This field is required")
      .oneOf([true], "You must agree to the terms and conditions"),
    ownerSignature: Yup.mixed().required("Owner Signature is required"),
    fees: Yup.object().shape({
      registrationFee: Yup.string().required().label("Registration Fee"),
      prepayDNAFee: Yup.boolean().required().label("Prepay DNA Fee"),
      prepayDNAFeeAmount: Yup.string()
        .optional()
        .label("Prepay DNA Fee Amount").nullable(),
    }),
    ...(!mode
      ? {
          firstChoice: Yup.string().trim().required().label("First Choice"),
          secondChoice: Yup.string().trim().required().label("Second Choice"),
          thirdChoice: Yup.string().trim().required().label("Third Choice"),
          billingInfo: Yup.object().shape({
            billingAddress: Yup.string()
              .trim()
              .required()
              .label("Billing Address"),
            phoneNumber: Yup.string().trim().required().label("Phone Number"),
            email: Yup.string().trim().email().required().label("Email"),
            accountHolderName: Yup.string()
              .trim()
              .required()
              .label("Account Holder Name"),
            accountHolderSignature: Yup.mixed().required(
              "Account Holder Signature is required"
            ),
            paymentInfo: Yup.object().shape({
              paymentMethod: Yup.string()
                .trim()
                .required()
                .label("Payment Method"),
              creditCard: Yup.object().shape({
                cardType: Yup.string().trim().nullable(),
                cardNumber: Yup.string().trim().nullable().label("Card Number"),
                expirationDate: Yup.string()
                  .trim()
                  .nullable()
                  .label("Expiration Date"),
                cvv: Yup.string().trim().nullable().label("CVV"),
              }),
              bankAccount: Yup.object().when("paymentMethod", {
                is: (paymentMethod) => paymentMethod === "Bank",
                then: (schema) =>
                  schema.shape({
                    accountType: Yup.string()
                      .trim()
                      .required()
                      .label("Account Type"),
                    accountName: Yup.string()
                      .trim()
                      .required()
                      .label("Account Name"),
                    bankName: Yup.string().trim().required().label("Bank Name"),
                    routingNumber: Yup.string()
                      .trim()
                      .required()
                      .label("Routing Number"),
                    accountNumber: Yup.string()
                      .trim()
                      .required()
                      .label("Account Number"),
                  }),
                otherwise: (schema) => schema.notRequired().nullable(),
              }),
            }),
          }),
          checkedAgreement: Yup.boolean()
            .required()
            .label("Agreement")
            .oneOf([true], "You must agree to the terms and conditions"),
        }
      : {
          firstChoice: Yup.string().trim().nullable().label("First Choice"),
          secondChoice: Yup.string().trim().nullable().label("Second Choice"),
          thirdChoice: Yup.string().trim().nullable().label("Third Choice"),
          billingInfo: Yup.object().shape({
            billingAddress: Yup.string()
              .trim()
              .nullable()
              .label("Billing Address"),
            phoneNumber: Yup.string().trim().nullable().label("Phone Number"),
            email: Yup.string().trim().email().nullable().label("Email"),
            accountHolderName: Yup.string()
              .trim()
              .nullable()
              .label("Account Holder Name"),
            accountHolderSignature: Yup.mixed().nullable(),
            paymentInfo: Yup.object().shape({
              paymentMethod: Yup.string()
                .trim()
                .nullable()
                .label("Payment Method"),
              creditCard: Yup.object().shape({
                cardType: Yup.string().trim().nullable(),
                cardNumber: Yup.string().trim().nullable().label("Card Number"),
                expirationDate: Yup.string()
                  .trim()
                  .nullable()
                  .label("Expiration Date"),
                cvv: Yup.string().trim().nullable().label("CVV"),
              }),
              bankAccount: Yup.object().when("paymentMethod", {
                is: (paymentMethod) => paymentMethod === "Bank",
                then: (schema) =>
                  schema.shape({
                    accountType: Yup.string()
                      .trim()
                      .nullable()
                      .label("Account Type"),
                    accountName: Yup.string()
                      .trim()
                      .nullable()
                      .label("Account Name"),
                    bankName: Yup.string().trim().nullable().label("Bank Name"),
                    routingNumber: Yup.string()
                      .trim()
                      .nullable()
                      .label("Routing Number"),
                    accountNumber: Yup.string()
                      .trim()
                      .nullable()
                      .label("Account Number"),
                  }),
                otherwise: (schema) => schema.notRequired().nullable(),
              }),
            }),
          }),
          checkedAgreement: Yup.boolean().nullable().label("Agreement"),
        }),

    // billingInfo: Yup.object().shape({
    //   billingAddress: Yup.string().trim().required().label("Billing Address"),
    //   phoneNumber: Yup.string().trim().required().label("Phone Number"),
    //   email: Yup.string().trim().email().required().label("Email"),
    //   accountHolderName: Yup.string()
    //     .trim()
    //     .required()
    //     .label("Account Holder Name"),
    //   accountHolderSignature: Yup.mixed().required(
    //     "Account Holder Signature is required"
    //   ),
    //   paymentInfo: Yup.object().shape({
    //     paymentMethod: Yup.string().trim().required().label("Payment Method"),
    //     creditCard: Yup.object().shape({
    //       cardType: Yup.string().trim().nullable(),
    //       cardNumber: Yup.string().trim().nullable().label("Card Number"),
    //       expirationDate: Yup.string()
    //         .trim()
    //         .nullable()
    //         .label("Expiration Date"),
    //       cvv: Yup.string().trim().nullable().label("CVV"),
    //     }),
    //     bankAccount: Yup.object().when("paymentMethod", {
    //       is: (paymentMethod) => paymentMethod === "Bank",
    //       then: (schema) =>
    //         schema.shape({
    //           accountType: Yup.string().trim().required().label("Account Type"),
    //           accountName: Yup.string().trim().required().label("Account Name"),
    //           bankName: Yup.string().trim().required().label("Bank Name"),
    //           routingNumber: Yup.string()
    //             .trim()
    //             .required()
    //             .label("Routing Number"),
    //           accountNumber: Yup.string()
    //             .trim()
    //             .required()
    //             .label("Account Number"),
    //         }),
    //       otherwise: (schema) => schema.notRequired().nullable(),
    //     }),
    //   }),
    // }),
    // checkedAgreement: Yup.boolean()
    //   .required()
    //   .label("Aggrement")
    //   .oneOf([true], "You must agree to the terms and conditions"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    reset,
    setValue,
    watch,
    trigger,
    clearErrors,
    control,
    setError,
    getValues
  } = useForm({
    resolver: yupResolver(horseSchema),
    defaultValues: formObject,
    mode: "onChange", // This will help with immediate validation
    reValidateMode: "onChange",
  });
  console.log("the value is", getValues())

  // Add this to track form state changes
  useEffect(() => {
    console.log("Form State Changed");
    console.log("Current Errors:", errors);
    console.log("Dirty Fields:", dirtyFields);
  }, [errors, dirtyFields,openCheckoutPage]);

  const inputStyle = {
    base: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#000", // Ensure the text is black or dark gray
      "::placeholder": {
        color: "#6c757d", // Light gray placeholder
      },
      padding: "10px 14px", // Adjust for comfortable spacing
      borderRadius: "8px", // Rounded corners
      border: "2px solid #d6bcfa", // Light purple border
      backgroundColor: "transparent", // Transparent background
      outline: "none", // Remove default focus outline
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  const onModalShow = useCallback(() => {
    setErrMsg("");
    setIsSubmitted(false)
    setCheckoutPage(false)
    clearInterval(intervalRef.current);
    if (modalData && modalData?.registrationNum) {
      const {
        registrationType,
        registrationNum,
        performanceLink,
        stateFoaledIn,
        bredBy,
        color,
        pattern,
        // pedigree,
        sex,
        otherRegistries,
        foalDate,
        breedingDate,
        DNA,
        DNA_CASE,
        DNA_TEST_DATE,
        dnaTestSubmission,
        dam,
        sire,
        owners,
        ownerType,
        registrationDocument,
        checkedOwnerInfo,
        othermarkings,
        markingDocuments,
        ownerSignature,
      } = modalData;

      setValue("registrationType", registrationType);
      setValue("registrationNum", registrationNum || "");
      setValue("performanceLink", performanceLink || "");
      setValue("registrationDocument", null);
      setValue("stateFoaledIn", stateFoaledIn || "");
      // setValue("pedigree", pedigree || "");
      setValue("foalDate", foalDate ? foalDate.split("T")[0] : null);
      setValue(
        "breedingDate",
        breedingDate ? breedingDate.split("T")[0] : null
      );
      setValue("bredBy", bredBy || "");
      setValue("sex", sex || "");
      setValue("color", color || "");
      setValue("pattern", pattern || "");
      setValue("DNA", DNA || "");
      setValue("DNA_CASE", DNA_CASE || "");
      setValue(
        "DNA_TEST_DATE",
        DNA_TEST_DATE ? DNA_TEST_DATE.split("T")[0] : "" // Extracting the date part
      );
      setValue("dnaTestSubmission", dnaTestSubmission || "");
      setValue("ownerType", ownerType || "");
      setValue("checkedOwnerInfo", checkedOwnerInfo);
      setValue("markingDocuments", []);
      setValue("ownerSignature", ownerSignature);
      //for the sire and dam
      if (sire) {
        // Main Sire Information
        setValue("sire_name", sire.name || "");
        setValue("sire_reg", sire.reg || "");
        setValue("sire_other_reg_name", sire.otherRegName || "");
        setValue("sire_other_reg_number", sire.otherRegNum || "");
        setValue("sire_paperwork", null);

        // Grand Sire (Sire) Information
        if (sire.grand_sire) {
          setValue("sire_grand_sire_name", sire.grand_sire.name || "");
          setValue("sire_grand_sire_reg", sire.grand_sire.reg || "");
          setValue(
            "sire_grand_sire_other_name",
            sire.grand_sire.otherRegName || ""
          );
          setValue(
            "sire_grand_sire_other_number",
            sire.grand_sire.otherRegNum || ""
          );
          setValue(
            "sire_grand_sire_paperwork",
            null
          );
        }

        // Grand Dam (Sire) Information
        if (sire.grand_dam) {
          setValue("sire_grand_dam_name", sire.grand_dam.name || "");
          setValue("sire_grand_dam_reg", sire.grand_dam.reg || "");
          setValue(
            "sire_grand_dam_other_name",
            sire.grand_dam.otherRegName || ""
          );
          setValue(
            "sire_grand_dam_other_number",
            sire.grand_dam.otherRegNum || ""
          );
          setValue(
            "sire_grand_dam_paperwork",
            null
          );
        }

        // Great-Grand Sire (Sire) Information
        if (sire.great_grand_sire) {
          setValue(
            "sire_great_grand_sire_name",
            sire.great_grand_sire.name || ""
          );
          setValue(
            "sire_great_grand_sire_reg",
            sire.great_grand_sire.reg || ""
          );
          setValue(
            "sire_great_grand_sire_other_name",
            sire.great_grand_sire.otherRegName || ""
          );
          setValue(
            "sire_great_grand_sire_other_number",
            sire.great_grand_sire.otherRegNum || ""
          );
          setValue(
            "sire_great_grand_sire_paperwork",
           null
          );
        }

        // Great-Grand Dam (Sire) Information
        if (sire.great_grand_dam) {
          setValue(
            "sire_great_grand_dam_name",
            sire.great_grand_dam.name || ""
          );
          setValue("sire_great_grand_dam_reg", sire.great_grand_dam.reg || "");
          setValue(
            "sire_great_grand_dam_other_name",
            sire.great_grand_dam.otherRegName || ""
          );
          setValue(
            "sire_great_grand_dam_other_number",
            sire.great_grand_dam.otherRegNum || ""
          );
          setValue(
            "sire_great_grand_dam_paperwork",
            null
          );
        }
      }
      if(dam) {
        setValue("dam_name", dam.name || "");
        setValue("dam_reg", dam.reg || "");
        setValue("dam_other_reg_name", dam.otherRegName || "");
        setValue("dam_other_reg_number", dam.otherRegNum || "");
        setValue("dam_paperwork", null);

        // Grand Sire (Sire) Information
        if (dam.grand_sire) {
          setValue("dam_grand_sire_name", dam.grand_sire.name || "");
          setValue("dam_grand_sire_reg", dam.grand_sire.reg || "");
          setValue("dam_grand_sire_other_name", dam.grand_sire.otherRegName || "");
          setValue("dam_grand_sire_other_number", dam.grand_sire.otherRegNum || "");
          setValue("dam_grand_sire_paperwork", null);
        }

        // Grand Dam (Sire) Information
        if (dam.grand_dam) {
          setValue("dam_grand_dam_name", dam.grand_dam.name || "");
          setValue("dam_grand_dam_reg", dam.grand_dam.reg || "");
          setValue("dam_grand_dam_other_name", dam.grand_dam.otherRegName || "");
          setValue("dam_grand_dam_other_number", dam.grand_dam.otherRegNum || "");
          setValue("dam_grand_dam_paperwork", null);
        }

        // Great-Grand Sire (Sire) Information
        if (dam.great_grand_sire) {
          setValue("dam_great_grand_sire_name", dam.great_grand_sire.name || "");
          setValue("dam_great_grand_sire_reg", dam.great_grand_sire.reg || "");
          setValue("dam_great_grand_sire_other_name", dam.great_grand_sire.otherRegName || "");
          setValue("dam_great_grand_sire_other_number", dam.great_grand_sire.otherRegNum || "");
          setValue("dam_great_grand_sire_paperwork", null);
        }

        // Great-Grand Dam (Sire) Information
        if (dam.great_grand_dam) {
          setValue("dam_great_grand_dam_name", dam.great_grand_dam.name || "");
          setValue("dam_great_grand_dam_reg", dam.great_grand_dam.reg || "");
          setValue("dam_great_grand_dam_other_name", dam.great_grand_dam.otherRegName || "");
          setValue("dam_great_grand_dam_other_number", dam.great_grand_dam.otherRegNum || "");
          setValue("dam_great_grand_dam_paperwork", null);
        }
      }

      if (owners?.length > 0) {
        owners.forEach((owner, index) => {
          setValue(`owners.${index}.memberNum`, owner.memberNum || "");
          setValue(`owners.${index}.name`, owner.name || "");
          setValue(`owners.${index}.address`, owner.address || "");
          setValue(`owners.${index}.city`, owner.city || "");
          setValue(`owners.${index}.state`, owner.state || "");
          setValue(`owners.${index}.zip`, owner.zip || "");
        });
      } else {
        setValue("owners", owners);
      }
      if (otherRegistries?.length > 0) {
        otherRegistries.forEach((registry, index) => {
          setValue(
            `otherRegistries.${index}.registryName`,
            registry.registryName || ""
          );
          setValue(
            `otherRegistries.${index}.registryNumber`,
            registry.registryNumber || ""
          );
          setValue(
            `otherRegistries.${index}.registryPaperwork`,
            null
          );
        });
      } else {
        setValue("otherRegistries", otherRegistries);
      }
      if (othermarkings) {
        setValue("othermarkings_left", othermarkings.left || "");
        setValue("othermarkings_right", othermarkings.right || "");
        setValue("othermarkings_face", othermarkings.face || "");
        setValue("othermarkings_chin", othermarkings.chin || "");
        setValue("othermarkings_frontLeg", othermarkings.frontLeg || "");
        setValue("othermarkings_hindLeg", othermarkings.hindLeg || "");
        setValue(
          "othermarkings_maneColor",
          othermarkings.maneColor || "mainColor"
        );
        setValue(
          "othermarkings_tailColor",
          othermarkings.tailColor || "taileColor"
        );
      }
    } else {
      reset(formObject);
    }
  }, [modalData, setValue, reset]);

  const onModalClose = useCallback(() => {    
    reset();
    setModalState(false);
    setErrMsg("");
    clearInterval(intervalRef.current);
  }, []);

  const cleanObject = (obj) => {
    const cleanedObj = Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value && value !== "")
    );
    return Object.keys(cleanedObj).length === 0 ? null : cleanedObj;
  };

  const appendPaperwork = async (data, formData, type) => {
    data.forEach((paperwork, index) => {
      // Check if the paperwork exists and is not empty
      if (paperwork && paperwork[0]) {
        formData.append(`${type}RegistrationPaperwork${index}`, paperwork[0]);
      }
    });
  };

  const onFormSubmit = useCallback(
    async (data) => {
      const { registrationDocument, registrationType } = data;
      const {
        registrationNum,
        // pedigree,
        verified,
        performanceDocument,
        performanceVerification,
        performanceLink,
        stateFoaledIn,
        breed,
        foalDate,
        breedingDate,
        firstChoice,
        secondChoice,
        thirdChoice,
        otherRegistries,
        sex,
        color,
        pattern,
        DNA,
        DNA_CASE,
        DNA_TEST_DATE,
        dnaTestSubmission,
        // ----- Sire fields
        sire_name,
        sire_reg,
        sire_other_reg_name,
        sire_other_reg_number,
        sire_paperwork,
        sire_grand_sire_name,
        sire_grand_sire_reg,
        sire_grand_sire_other_name,
        sire_grand_sire_other_number,
        sire_grand_sire_paperwork,
        sire_grand_dam_name,
        sire_grand_dam_reg,
        sire_grand_dam_other_name,
        sire_grand_dam_other_number,
        sire_grand_dam_paperwork,
        sire_great_grand_sire_name,
        sire_great_grand_sire_reg,
        sire_great_grand_sire_other_name,
        sire_great_grand_sire_other_number,
        sire_great_grand_sire_paperwork,
        sire_great_grand_dam_name,
        sire_great_grand_dam_reg,
        sire_great_grand_dam_other_name,
        sire_great_grand_dam_other_number,
        sire_great_grand_dam_paperwork,

        //------dam
        dam_name,
        dam_reg,
        dam_other_reg_name,
        dam_other_reg_number,
        dam_paperwork,
        dam_grand_sire_name,
        dam_grand_sire_reg,
        dam_grand_sire_other_name,
        dam_grand_sire_other_number,
        dam_grand_sire_paperwork,
        dam_grand_dam_name,
        dam_grand_dam_reg,
        dam_grand_dam_other_name,
        dam_grand_dam_other_number,
        dam_grand_dam_paperwork,
        dam_great_grand_sire_name,
        dam_great_grand_sire_reg,
        dam_great_grand_sire_other_name,
        dam_great_grand_sire_other_number,
        dam_great_grand_sire_paperwork,
        dam_great_grand_dam_name,
        dam_great_grand_dam_reg,
        dam_great_grand_dam_other_name,
        dam_great_grand_dam_other_number,
        dam_great_grand_dam_paperwork,

        othermarkings_chin,
        othermarkings_face,
        othermarkings_frontLeg,
        othermarkings_hindLeg,
        othermarkings_left,
        othermarkings_maneColor,
        othermarkings_right,
        othermarkings_tailColor,

        ownerType,
        owners,
        checkedOwnerInfo,
        ownerSignature,
        fees,
        billingInfo,
        checkedAgreement,
        markingDocuments,
        _id,
        ...restData
      } = data;

      setIsSubmitted(true);
      setCheckoutPage(true);

      const sirePaperWork = [
        sire_paperwork,
        sire_grand_sire_paperwork,
        sire_grand_dam_paperwork,
        sire_great_grand_sire_paperwork,
        sire_great_grand_dam_paperwork,
      ];

      const damPaperwork = [
        dam_paperwork,
        dam_grand_sire_paperwork,
        dam_grand_dam_paperwork,
        dam_great_grand_sire_paperwork,
        dam_great_grand_dam_paperwork,
      ];

      const sire = cleanObject({
        name: sire_name,
        reg: sire_reg,
        otherRegName: sire_other_reg_name,
        otherRegNum: sire_other_reg_number,
        grand_sire: cleanObject({
          name: sire_grand_sire_name,
          reg: sire_grand_sire_reg,
          otherRegName: sire_grand_sire_other_name,
          otherRegNum: sire_grand_sire_other_number,
        }),
        grand_dam: cleanObject({
          name: sire_grand_dam_name,
          reg: sire_grand_dam_reg,
          otherRegName: sire_grand_dam_other_name,
          otherRegNum: sire_grand_dam_other_number,
        }),
        great_grand_sire: cleanObject({
          name: sire_great_grand_sire_name,
          reg: sire_great_grand_sire_reg,
          otherRegName: sire_great_grand_sire_other_name,
          otherRegNum: sire_great_grand_sire_other_number,
        }),
        great_grand_dam: cleanObject({
          name: sire_great_grand_dam_name,
          reg: sire_great_grand_dam_reg,
          otherRegName: sire_great_grand_dam_other_name,
          otherRegNum: sire_great_grand_dam_other_number,
        }),
      });

      const dam = cleanObject({
        name: dam_name,
        reg: dam_reg,
        otherRegName: dam_other_reg_name,
        otherRegNum: dam_other_reg_number,
        grand_sire: cleanObject({
          name: dam_grand_sire_name,
          reg: dam_grand_sire_reg,
          otherRegName: dam_grand_sire_other_name,
          otherRegNum: dam_grand_sire_other_number,
        }),
        grand_dam: cleanObject({
          name: dam_grand_dam_name,
          reg: dam_grand_dam_reg,
          otherRegName: dam_grand_dam_other_name,
          otherRegNum: dam_grand_dam_other_number,
        }),
        great_grand_sire: cleanObject({
          name: dam_great_grand_sire_name,
          reg: dam_great_grand_sire_reg,
          otherRegName: dam_great_grand_sire_other_name,
          otherRegNum: dam_great_grand_sire_other_number,
        }),
        great_grand_dam: cleanObject({
          name: dam_great_grand_dam_name,
          reg: dam_great_grand_dam_reg,
          otherRegName: dam_great_grand_dam_other_name,
          otherRegNum: dam_great_grand_dam_other_number,
        }),
      });

      const otherMarking = cleanObject({
        left: othermarkings_left,
        right: othermarkings_right,
        face: othermarkings_face,
        chin: othermarkings_chin,
        frontLeg: othermarkings_frontLeg,
        hindLeg: othermarkings_hindLeg,
        maneColor: othermarkings_maneColor,
        tailColor: othermarkings_tailColor,
      });

      const { accountHolderSignature, ...billingInfoWithoutSignature } =
        billingInfo;

      if (
        billingInfoWithoutSignature.paymentInfo.paymentMethod === "Credit Card"
      ) {
        billingInfoWithoutSignature.paymentInfo.bankAccount = null;
      }

      // filer the otherregistries data if we don't have that

      const filteredOtherRegistries = otherRegistries
        .filter((item) => item.registryName && item.registryNumber) // Ensure values exist
        .map(({ registryName, registryNumber }) => ({
          registryName,
          registryNumber,
        }));

        
      const formData = new FormData();
      // registrationType.forEach((type, index) => {
        formData.append(`registrationType[0]`, registrationType);
      // }); // registration type

      formData.append("markingDocuments", markingDocuments[0]);
      formData.append("markingDocuments", markingDocuments[1]);
      formData.append("markingDocuments", markingDocuments[2]);
      formData.append("markingDocuments", markingDocuments[3]);

      // formData.append("registrationNum", registrationNum); // registration num
      // formData.append("pedigree", pedigree);
      formData.append("verified", verified);
      formData.append("registrationDocument", registrationDocument[0]);
      formData.append("performanceVerification",watch("performanceVerification") === "true" ? "Yes" : "No")

      formData.append("sire", JSON.stringify(sire));
      await appendPaperwork(sirePaperWork, formData, "sire");
      formData.append("dam", JSON.stringify(dam));
      await appendPaperwork(damPaperwork, formData, "dam");
      formData.append("othermarkings", JSON.stringify(otherMarking));
      formData.append("performanceLink", performanceLink);
      formData.append("stateFoaledIn", stateFoaledIn);
      formData.append("breed", breed);
      formData.append("foalDate", foalDate);
      formData.append("breedingDate", breedingDate);
      if (!mode) {
        formData.append("firstChoice", firstChoice);
        formData.append("secondChoice", secondChoice);
        formData.append("thirdChoice", thirdChoice);
      }
      formData.append(
        "otherRegistries",
        JSON.stringify(filteredOtherRegistries)
      );
      otherRegistries.forEach((item, index) => {
        if (item.registryPaperwork !== null) {
          formData.append(
            `registryPaperwork${index}`,
            item.registryPaperwork[0]
          );
        }
      });
      formData.append("sex", sex);
      formData.append("color", color);
      formData.append("pattern", pattern);
      formData.append("DNA", DNA);
      formData.append("DNA_CASE", DNA_CASE);
      formData.append("DNA_TEST_DATE", DNA_TEST_DATE);
      formData.append("dnaTestSubmission", dnaTestSubmission);
      formData.append("ownerType", ownerType);
      formData.append("owners", JSON.stringify(owners));
      formData.append("checkedOwnerInfo", checkedOwnerInfo);
      formData.append("ownerSignature", ownerSignature);
      formData.append("fees", JSON.stringify(fees));
      formData.append("accountHolderSign", accountHolderSignature);
      delete billingInfoWithoutSignature.paymentInfo.creditCard;
      if (!mode) {
        formData.append(
          "billingInfo",
          JSON.stringify(billingInfoWithoutSignature)
        );
      }
      formData.append("checkedAgreement", checkedAgreement);

      if(openCheckoutPage) {
        setIsSubmitted(true)
        const response = await createHorse(formData);
        if (response?.success && !mode) {
          paymentInfoFun(response.data);
        }
        if (response?.success && mode) {
          setModalState(false);
          console.log("The API updating succesfully", response);
          fetchData();
          setErrMsg("");
          setIsSubmitted(false);
        }
      } 
    },
    [onModalClose, trigger, errors]
  );

  const owners = watch("owners");

  const toastMessage = (message, type) => {
    return (
      <CToast
        autohide={false}
        color={type}
        visible={true}
        className="align-items-center text-white"
      >
        <div className="d-flex">
          <CToastBody className="w-100">{message}</CToastBody>
          <CToastClose className="me-2 m-auto" />
        </div>
      </CToast>
    );
  };

  return (
    <CModal
      backdrop="static"
      visible={modalState}
      size="lg"
      onShow={onModalShow}
      ignoreBackdropClick 
      onClose={onModalClose}
    >
      <CModalHeader onClose={onModalClose}>
        <CModalTitle>
          {(mode === "view" && "View Horse") ||
            (mode === "edit" && "Edit Horse") ||
            "Register Horse"}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div>
          <CForm>
            <CToaster
              className="p-3"
              placement="top-end"
              color="primary"
              push={errMsg}
            />
            <CCol>
              <CFormLabel>Registration Type</CFormLabel>
              <div className="d-flex gap-3 items-center">
                {["Proven", "Unproven", "OneSided"].map((type) => {
                  const id = `registrationType-${type}`;
                  return (
                    <CFormCheck
                      key={type}
                      id={id}
                      label={type}
                      name="registrationType"
                      value={type}
                      type="radio" // Specify radio type
                      checked={watch("registrationType") === type} // Radio selection
                      {...register("registrationType", {
                        onChange: (e) => {
                          const value = e.target.value;
                          setValue("registrationType", value); // Update single value
                        },
                      })}
                      invaliad={!!errors?.registrationType}
                      disabled={mode === "view"}
                    />
                  );
                })}
                {errors?.registrationType && (
                  <CFormFeedback className="d-block" invalid={true}>
                    {errors?.registrationType?.message}
                  </CFormFeedback>
                )}
              </div>
            </CCol>

            <CRow className="mt-3">
              <CCol>
                <CFormLabel>ISHA Registration Number *</CFormLabel>
                <CFormInput
                  type="text"
                  name="registrationNum"
                  placeholder="Registration Number"
                  autoComplete="text"
                  invalid={!!errors?.registrationNum}
                  {...register("registrationNum", {
                    onChange: (e) => {
                      trigger("registrationNum");
                    },
                  })}
                  disabled
                />
                {errors?.registrationNum && (
                  <CFormFeedback className="d-block" invalid={true}>
                    {errors?.registrationNum?.message}
                  </CFormFeedback>
                )}
              </CCol>
            </CRow>
            <div className="mt-3">
            {mode === 'edit' ? ('') : (
              <>
                <CCol className="d-flex gap-3">
                  <CFormLabel>Performance Verification * :</CFormLabel>
                  <CFormCheck
                    type="radio"
                    label="Yes"
                    name="performanceVerification"
                    value="true"
                    {...register(`performanceVerification`, {
                      onChange: (e) => {
                        trigger(`performanceVerification`);
                        setValue('performanceVerification',true)
                      },
                    })}
                    disabled={mode === "view"}  
                  />
                  <CFormCheck
                    type="radio"
                    label="No"
                    name="performanceVerification"
                    value="false"
                    onChange={() => setValue('performanceVerification', false)}
                    {...register('performanceVerification', {
                      onChange: (e) => {
                        trigger("performanceVerification");
                      },
                    })}
                    disabled={mode === "view"}
                  />
                  {errors?.performanceVerification && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.performanceVerification?.message}
                    </CFormFeedback>
                  )}
                </CCol>
                <div className="mb-3">
                (If your horse is genuinely Racking/Singlefooting, 
                  obtaining verification will result in a "VERIFIED" stamp on the registration paperwork upon approval by the commissioner. 
                  Verification Fee: $20)
                </div>
              </>
            )}
              <CCol>
                <CFormLabel>Performace Verification Link: (Optional) </CFormLabel>
                <CFormInput
                  type="text"
                  name="performanceLink"
                  invalid={!!errors?.performanceLink}
                  {...register("performanceLink")}
                  disabled={mode === "view"}
                />
                {errors?.performanceLink && (
                  <CFormFeedback className="d-block" invalid={true}>
                    {errors?.performanceLink?.message}
                  </CFormFeedback>
                )}
                <div className="mb-3">
                  Provide a link to a video showcasing the horse performing a smooth Racking/Singlefooting gait. 
                  Horses exhibiting pacing or trotting will not be verified. (Dropbox, Youtube, TikTok, Reels or Vimeo recommended)
                </div>
              </CCol>
              <CCol className="mt-3">
                <CFormLabel>Performance Video Upload: (optional) </CFormLabel>
                <CFormLabel htmlFor={"performanceDocument"} className="w-100">
                  <CInputGroup>
                    <CInputGroupText>Choose File</CInputGroupText>
                    <CFormInput
                      type="text"
                      className="bg-transparent"
                      value={(() => {
                        const tempRegistrationDoc = watch(
                          "performanceDocument"
                        );
                        if (typeof tempRegistrationDoc === "string") {
                          return tempRegistrationDoc?.split("/")[1];
                        } else if (tempRegistrationDoc?.[0]) {
                          return tempRegistrationDoc[0].name;
                        } else {
                          return "No file uploaded";
                        }
                      })()}
                      readOnly
                      disabled
                    />
                  </CInputGroup>
                </CFormLabel>
                <CFormInput
                    id="performanceDocument"
                    type="file"
                    accept=".wmv,.mp4,video/wmv,video/mp4"
                    style={{ visibility: "hidden", display: "none" }}
                    invalid={!!errors?.performanceDocument}
                    {...register("performanceDocument", {
                      validate: (value) => {
                        if (value && value[0]) {
                          const fileType = value[0].type.toLowerCase();
                          const fileName = value[0].name.toLowerCase();
                          const validTypes = ['video/mp4', 'video/x-ms-wmv'];
                          const validExtensions = ['.mp4', '.wmv'];
                          
                          if (!validTypes.includes(fileType) && 
                              !validExtensions.some(ext => fileName.endsWith(ext))) {
                            return 'Please upload only WMV or MP4 video files';
                          }
                          
                          // Check file size (20MB = 20 * 1024 * 1024 bytes)
                          if (value[0].size > 20 * 1024 * 1024) {
                            return 'File size must not exceed 20MB';
                          }
                        }
                        return true;
                      }
                    })}
                    disabled={mode === "view"}
                />
                {errors?.performanceDocument && (
                  <CFormFeedback invalid={true}>
                    {errors?.performanceDocument?.message}
                  </CFormFeedback>
                )}
                <div>
                (Upload a video showcasing the horse performing a smooth Racking/Singlefooting gait. 
                  Horses exhibiting pacing or trotting will not be verified.) (WMV, MP4) (20MB Max)
                </div>
              </CCol>
              <CCol className="mt-3">
                <CFormLabel>Horse Registration Document *</CFormLabel>
                <CFormLabel htmlFor={"registrationDocument"} className="w-100">
                  <CInputGroup>
                    <CInputGroupText>Choose File</CInputGroupText>
                    <CFormInput
                      type="text"
                      className="bg-transparent"
                      value={(() => {
                        const tempRegistrationDoc = watch(
                          "registrationDocument"
                        );
                        if (typeof tempRegistrationDoc === "string") {
                          return tempRegistrationDoc?.split("/")[1];
                        } else if (tempRegistrationDoc?.[0]) {
                          return tempRegistrationDoc[0].name;
                        } else {
                          return "No file uploaded";
                        }
                      })()}
                      readOnly
                      disabled
                    />
                  </CInputGroup>
                </CFormLabel>

                <CFormInput
                  id="registrationDocument"
                  type="file"
                  accept=".pdf,.doc,.docx,.jpeg,.jpg"
                  style={{ visibility: "hidden", display: "none" }}
                  invalid={!!errors?.registrationDocument}
                  {...register("registrationDocument", {
                    required: "Horse Registration Document is required",
                    validate: {
                      fileFormat: (value) =>
                        !value ||
                        handleFileExtensionCheck(value) ||
                        "Invalid file format",
                      fileSize: (value) =>
                        !value ||
                        handleFileSizeCheck(value) ||
                        "File size exceeds limit",
                    },
                  })}
                  disabled={mode === "view"}
                />
                {errors?.registrationDocument && (
                  <CFormFeedback invalid={true}>
                    {errors?.registrationDocument?.message}
                  </CFormFeedback>
                )}
                <div>Accept only .pdf, .docx, .doc, .jpeg, .jpg(Max 10MB)</div>
              </CCol>
            </div>
            <div className="mt-3">
              <CFormLabel>Breeding Information:</CFormLabel>
              <CCol>
                <CFormLabel>State Foaled In *</CFormLabel>
                <CFormSelect
                  name="stateFoaledIn"
                  invalid={!!errors?.stateFoaledIn}
                  options={[...usaStatesList]}
                  {...register("stateFoaledIn", {
                    onChange: (e) => {
                      trigger("stateFoaledIn");
                    },
                  })}
                  disabled={mode === "view"}
                >
                  <option value="" disabled>
                    Select State
                  </option>
                  <option value="State 1">State 1</option>
                  <option value="State 2">State 2</option>
                </CFormSelect>
                {errors?.stateFoaledIn && (
                  <CFormFeedback className="d-block" invalid={true}>
                    {errors?.stateFoaledIn?.message}
                  </CFormFeedback>
                )}
              </CCol>
              <CCol className="mt-3">
                <CFormLabel>Breed</CFormLabel>
                <CFormInput
                  type="text"
                  name="breed"
                  invalid={!!errors?.breed}
                  {...register("breed", {
                    onChange: (e) => {
                      trigger("breed");
                    },
                  })}
                  disabled={mode === "view"}
                />
                {errors?.breed && (
                  <CFormFeedback className="d-block" invalid={true}>
                    {errors?.breed?.message}
                  </CFormFeedback>
                )}
              </CCol>
              {/* <CRow className="mt-3">
                <CCol>
                  <CFormLabel>Pedigree *</CFormLabel>
                  <div className="d-flex gap-3 items-center">
                    {["Proven", "Unproven"].map((type) => {
                      const id = `pedigreeType-${type}`;
                      return (
                        <CFormCheck
                          id={id}
                          key={type}
                          type="radio"
                          name="pedigree"
                          value={type}
                          label={type}
                          invalid={!!errors?.pedigree}
                          {...register("pedigree", {
                            onChange: (e) => {
                              trigger("pedigree");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                      );
                    })}
                  </div>
                  {errors?.pedigree && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.pedigree?.message}
                    </CFormFeedback>
                  )}
                </CCol>
              </CRow> */}
              <CRow className="mt-3">
                <CCol md={6}>
                  <CFormLabel>Foal Date *</CFormLabel>
                  <CFormInput
                    type="date"
                    name="foalDate"
                    invalid={!!errors?.foalDate}
                    {...register("foalDate", {
                      onChange: (e) => {
                        trigger("foalDate");
                      },
                    })}
                    disabled={mode === "view"}
                    max={today} 
                  />
                  {errors?.foalDate && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.foalDate?.message}
                    </CFormFeedback>
                  )}
                </CCol>

                <CCol md={6}>
                  <CFormLabel>Breeding Date *</CFormLabel>
                  <CFormInput
                    type="date"
                    name="breedingDate"
                    invalid={!!errors?.breedingDate}
                    {...register("breedingDate", {
                      onChange: (e) => {
                        trigger("breedingDate");
                      },
                    })}
                    disabled={mode === "view"}
                    min={watch("foalDate")}
                  />
                  {errors?.breedingDate && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.breedingDate?.message}
                    </CFormFeedback>
                  )}
                </CCol>
              </CRow>
              <CCol className="mt-3">
                <CFormLabel>Bred By</CFormLabel>
                <CFormInput
                  type="text"
                  name="bredBy"
                  invalid={!!errors?.bredBy}
                  {...register("bredBy", {
                    onChange: (e) => {
                      trigger("bredBy");
                    },
                  })}
                  disabled={mode === "view"}
                />
                {errors?.bredBy && (
                  <CFormFeedback className="d-block" invalid={true}>
                    {errors?.bredBy?.message}
                  </CFormFeedback>
                )}
              </CCol>
            </div>

            <CCol className="mt-3">
              <CFormLabel>Sex *</CFormLabel>
              <CFormSelect
                name="sex"
                invalid={!!errors?.sex}
                {...register("sex", {
                  onChange: (e) => {
                    trigger("sex");
                  },
                })}
                disabled={mode === "view"}
              >
                <option value="" disabled>
                  Select sex
                </option>
                <option value="Stallion">Stallion</option>
                <option value="Gelding">Gelding</option>
                <option value="Mare">Mare</option>
              </CFormSelect>
              {errors?.sex && (
                <CFormFeedback className="d-block" invalid={true}>
                  {errors?.sex?.message}
                </CFormFeedback>
              )}
            </CCol>
            {mode === "edit" ? (
              ""
            ) : (
              <CCol className="mt-3">
                <CFormLabel>Horse Name Choices *</CFormLabel>
                <CFormInput
                  placeholder="First Choice"
                  type="text"
                  name="firstChoice"
                  invalid={!!errors?.firstChoice}
                  {...register("firstChoice", {
                    onChange: (e) => {
                      trigger("firstChoice");
                    },
                  })}
                  className="mt-2"
                  disabled={mode === "view"}
                />
                {errors?.firstChoice && (
                  <CFormFeedback className="d-block" invalid={true}>
                    {errors?.firstChoice?.message}
                  </CFormFeedback>
                )}
                <CFormInput
                  placeholder="Second Choice"
                  type="text"
                  name="secondChoice"
                  invalid={!!errors?.secondChoice}
                  {...register("secondChoice", {
                    onChange: (e) => {
                      trigger("secondChoice");
                    },
                  })}
                  disabled={mode === "view"}
                  className="mt-2"
                />
                {errors?.secondChoice && (
                  <CFormFeedback className="d-block" invalid={true}>
                    {errors?.secondChoice?.message}
                  </CFormFeedback>
                )}
                <CFormInput
                  placeholder="Third Choice"
                  type="text"
                  name="thirdChoice"
                  invalid={!!errors?.thirdChoice}
                  {...register("thirdChoice", {
                    onChange: (e) => {
                      trigger("thirdChoice");
                    },
                  })}
                  disabled={mode === "view"}
                  className="mt-2"
                />
                {errors?.thirdChoice && (
                  <CFormFeedback className="d-block" invalid={true}>
                    {errors?.thirdChoice?.message}
                  </CFormFeedback>
                )}
              </CCol>
            )}

            <div className="mt-3">
              <CFormLabel>Other Registries:</CFormLabel>
              {[...Array(4)].map((_, index) => (
                <div key={index} className="mb-3">
                  <CRow>
                    <CCol>
                      <CFormInput
                        label={`Horse Registry # ${index + 1}`}
                        placeholder="Registry Name"
                        invalid={
                          !!errors?.otherRegistries?.[index]?.registryName
                        }
                        {...register(`otherRegistries.${index}.registryName`)}
                        disabled={mode === "view"}
                      />
                      {errors?.otherRegistries?.[index]?.registryName && (
                        <CFormFeedback invalid={true}>
                          {
                            errors?.otherRegistries?.[index]?.registryName
                              ?.message
                          }
                        </CFormFeedback>
                      )}
                    </CCol>
                    <CCol>
                      <CFormInput
                        label={`ID #`}
                        placeholder="Registry Number"
                        invalid={
                          !!errors?.otherRegistries?.[index]?.registryNumber
                        }
                        {...register(`otherRegistries.${index}.registryNumber`)}
                        disabled={mode === "view"}
                      />
                      {errors?.otherRegistries?.[index]?.registryNumber && (
                        <CFormFeedback invalid={true}>
                          {
                            errors?.otherRegistries?.[index]?.registryNumber
                              ?.message
                          }
                        </CFormFeedback>
                      )}
                    </CCol>
                  </CRow>
                  <CRow className="">
                    <CFormLabel>{`Registration Paperwork from Registry #${index + 1}. (Upload both front and back, if applicable)`}</CFormLabel>
                    <CFormLabel
                      htmlFor={`registryPaperwork-${index}`}
                      className="w-100"
                    >
                      <CInputGroup>
                        <CInputGroupText>Choose File</CInputGroupText>
                        <CFormInput
                          type="text"
                          className="bg-transparent"
                          value={(() => {
                            const tempRegistryPaperwork = watch(
                              `otherRegistries.${index}.registryPaperwork`
                            );
                            if (typeof tempRegistryPaperwork === "string") {
                              return tempRegistryPaperwork?.split("/")[1];
                            } else if (tempRegistryPaperwork?.[0]) {
                              return tempRegistryPaperwork[0].name;
                            } else {
                              return "No file uploaded";
                            }
                          })()}
                          readOnly
                          disabled
                        />
                      </CInputGroup>
                    </CFormLabel>
                    <CFormInput
                      id={`registryPaperwork-${index}`}
                      type="file"
                      accept=".pdf,.doc,.docx,.jpeg,.jpg"
                      style={{ visibility: "hidden", display: "none" }}
                      invalid={
                        !!errors?.otherRegistries?.[index]?.registryPaperwork
                      }
                      {...register(
                        `otherRegistries.${index}.registryPaperwork`,
                        {
                          validate: {
                            fileFormat: (value) =>
                              !value ||
                              handleFileExtensionCheck(value) ||
                              "Invalid file format",
                            fileSize: (value) =>
                              !value ||
                              handleFileSizeCheck(value) ||
                              "File size exceeds limit",
                          },
                        }
                      )}
                      disabled={mode === "view"}
                    />
                    {errors?.otherRegistries?.[index]?.registryPaperwork && (
                      <CFormFeedback invalid={true}>
                        {
                          errors?.otherRegistries?.[index]?.registryPaperwork
                            ?.message
                        }
                      </CFormFeedback>
                    )}
                    <div>
                      Accept only .pdf, .docx, .doc, .jpeg, .jpg(Max 10MB)
                    </div>
                  </CRow>
                </div>
              ))}
            </div>

            <div className="">
              <CRow className="mt-3">
                <CCol>
                  <CFormLabel>Previously DNA Tested *</CFormLabel>
                  <CRow className="">
                    <CCol className="d-flex gap-3">
                      <CFormCheck
                        type="radio"
                        name="DNA"
                        id="dnaYes"
                        label="Yes"
                        value="Yes"
                        invalid={!!errors?.DNA}
                        {...register("DNA", {
                          onChange: (e) => {
                            trigger("DNA");
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      <CFormCheck
                        type="radio"
                        name="DNA"
                        id="dnaNo"
                        label="No"
                        value="No"
                        invalid={!!errors?.DNA}
                        {...register("DNA", {
                          onChange: (e) => {
                            trigger("DNA");
                          },
                        })}
                        disabled={mode === "view"}
                      />
                    </CCol>
                    {errors?.DNA && (
                      <CFormFeedback className="d-block" invalid={true}>
                        {errors?.DNA?.message}
                      </CFormFeedback>
                    )}
                  </CRow>
                </CCol>
              </CRow>
              {watch("DNA") === "Yes" && (
                <>
                  <CRow className="mt-3">
                    <CCol md={6}>
                      <CFormLabel>DNA Case</CFormLabel>
                      <CFormInput
                        placeholder="DNA Case"
                        name="DNA_CASE"
                        autoComplete="text"
                        invalid={!!errors?.DNA_CASE}
                        {...register("DNA_CASE", {
                          onChange: (e) => {
                            trigger("DNA_CASE");
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      {errors?.DNA_CASE && (
                        <CFormFeedback invalid={true}>
                          {errors?.DNA_CASE?.message}
                        </CFormFeedback>
                      )}
                    </CCol>

                    <CCol md={6}>
                      <CFormLabel>DNA Test Date *</CFormLabel>
                      <CFormInput
                        type="date"
                        name="DNA_TEST_DATE"
                        invalid={!!errors?.DNA_TEST_DATE}
                        {...register("DNA_TEST_DATE", {
                          onChange: (e) => {
                            trigger("DNA_TEST_DATE");
                          },
                        })}
                        disabled={mode === "view"}
                      />

                      {errors?.DNA_TEST_DATE && (
                        <CFormFeedback className="d-block" invalid={true}>
                          {errors?.DNA_TEST_DATE?.message}
                        </CFormFeedback>
                      )}
                    </CCol>
                  </CRow>
                </>
              )}
              <CRow className="mt-3">
                <CCol>
                  <CFormLabel>
                    DNA Testing Form will be submitted within the next 30 Days ?
                  </CFormLabel>
                  <CRow className="">
                    <CCol className="d-flex gap-3">
                      <CFormCheck
                        type="radio"
                        name="dnaTestSubmission"
                        id="dnaTestSubmissionYes"
                        label="Yes"
                        value="Yes"
                        invalid={!!errors?.dnaTestSubmission}
                        {...register("dnaTestSubmission", {
                          onChange: (e) => {
                            trigger("dnaTestSubmission");
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      <CFormCheck
                        type="radio"
                        name="dnaTestSubmission"
                        id="dnaTestSubmissionNo"
                        label="No"
                        value="No"
                        invalid={!!errors?.dnaTestSubmission}
                        {...register("dnaTestSubmission", {
                          onChange: (e) => {
                            trigger("dnaTestSubmission");
                          },
                        })}
                        disabled={mode === "view"}
                      />
                    </CCol>
                    {errors?.dnaTestSubmission && (
                      <CFormFeedback className="d-block" invalid={true}>
                        {errors?.dnaTestSubmission?.message}
                      </CFormFeedback>
                    )}
                  </CRow>
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSire(true); // Set Sire to true when Sire button is clicked
                    }}
                    style={{
                      width: "100%",
                      background: Sire ? "#768192" : "transparent",
                      border: "none",
                      boxShadow: "0 1px 4px 1px rgba(0, 0, 0, .4)",
                      padding: "9px",
                      color: Sire ? "#fff" : "#000",
                      fontWeight: 500,
                    }}
                  >
                    Sire
                  </button>
                </CCol>
                <CCol>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSire(false);
                    }}
                    style={{
                      width: "100%",
                      background: !Sire ? "#768192" : "transparent",
                      border: "none",
                      boxShadow: "0 1px 4px 1px rgba(0, 0, 0, .4)",
                      padding: "9px",
                      color: !Sire ? "#fff" : "#000",
                      fontWeight: 500,
                    }}
                  >
                    Dam
                  </button>
                </CCol>
              </CRow>

              {Sire && (
                <>
                  <div>
                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Sire Name"
                          placeholder="Sire Name"
                          autoComplete="text"
                          id="sire1"
                          invalid={!!errors?.sire_name}
                          {...register("sire_name", {
                            onChange: (e) => {
                              trigger("sire_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Sire ISHA Registration"
                          placeholder="Sire Reg #"
                          autoComplete="text"
                          invalid={!!errors?.sire_reg}
                          {...register("sire_reg", {
                            onChange: (e) => {
                              trigger("sire_reg");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_reg && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_reg?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Sire Other Registration Name"
                          placeholder="Sire Other Registration Name"
                          autoComplete="text"
                          invalid={!!errors?.sire_other_reg_name}
                          {...register("sire_other_reg_name", {
                            onChange: (e) => {
                              trigger("sire_other_reg_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_other_reg_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_other_reg_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Sire Other Registration Number"
                          placeholder="Sire Other Registration Number"
                          autoComplete="text"
                          invalid={!!errors?.sire_other_reg_number}
                          {...register("sire_other_reg_number", {
                            onChange: (e) => {
                              trigger("sire_other_reg_number");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_other_reg_number && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_other_reg_number?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CFormLabel>Sire Registration Paperwork </CFormLabel>
                      <CFormLabel htmlFor={"sire_paperwork"} className="w-100">
                        <CInputGroup>
                          <CInputGroupText>Choose File</CInputGroupText>
                          <CFormInput
                            type="text"
                            className="bg-transparent"
                            value={(() => {
                              const tempRegistryPaperwork =
                                watch("sire_paperwork");
                              if (typeof tempRegistryPaperwork === "string") {
                                return tempRegistryPaperwork?.split("/")[1];
                              } else if (tempRegistryPaperwork?.[0]) {
                                return tempRegistryPaperwork[0].name;
                              } else {
                                return "No file uploaded";
                              }
                            })()}
                            readOnly
                            disabled
                          />
                        </CInputGroup>
                      </CFormLabel>
                      <CFormInput
                        id="sire_paperwork"
                        type="file"
                        accept=".pdf,.doc,.docx,.jpeg,.jpg"
                        style={{ visibility: "hidden", display: "none" }}
                        invalid={!!errors?.sire_paperwork}
                        {...register("sire_paperwork", {
                          validate: {
                            fileFormat: (value) =>
                              !value ||
                              handleFileExtensionCheck(value) ||
                              "Invalid file format",
                            fileSize: (value) =>
                              !value ||
                              handleFileSizeCheck(value) ||
                              "File size exceeds limit",
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      {errors?.sire_paperwork && (
                        <CFormFeedback invalid={true}>
                          {errors?.sire_paperwork?.message}
                        </CFormFeedback>
                      )}
                    </CRow>

                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Grand Sire (Sire) Name"
                          placeholder="Grand Sire (Sire) Name"
                          autoComplete="text"
                          invalid={!!errors?.sire_grand_sire_name}
                          {...register("sire_grand_sire_name", {
                            onChange: (e) => {
                              trigger("sire_grand_sire_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_grand_sire_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_grand_sire_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Grand Sire (Sire) ISHA Registration"
                          placeholder="Grand Sire (Sire) Reg #"
                          autoComplete="text"
                          invalid={!!errors?.sire_grand_sire_reg}
                          {...register("sire_grand_sire_reg", {
                            onChange: (e) => {
                              trigger("sire_grand_sire_reg");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_grand_sire_reg && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_grand_sire_reg?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Grand Sire (Sire) Other Reg Name"
                          placeholder="Grand Sire Other Reg Name"
                          autoComplete="text"
                          invalid={!!errors?.sire_grand_sire_other_name}
                          {...register("sire_grand_sire_other_name", {
                            onChange: (e) => {
                              trigger("sire_grand_sire_other_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_grand_sire_other_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_grand_sire_other_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Grand Sire (Sire) Other Reg Number"
                          placeholder="Grand Sire (Sire) Other Reg Number"
                          autoComplete="text"
                          invalid={!!errors?.sire_grand_sire_other_number}
                          {...register("sire_grand_sire_other_number", {
                            onChange: (e) => {
                              trigger("sire_grand_sire_other_number");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_grand_sire_other_number && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_grand_sire_other_number?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CFormLabel>
                        Grand Sire (Sire) Registration Paperwork{" "}
                      </CFormLabel>
                      <CFormLabel
                        htmlFor={"sire_grand_sire_paperwork"}
                        className="w-100"
                      >
                        <CInputGroup>
                          <CInputGroupText>Choose File</CInputGroupText>
                          <CFormInput
                            type="text"
                            className="bg-transparent"
                            value={(() => {
                              const tempRegistryPaperwork = watch(
                                "sire_grand_sire_paperwork"
                              );
                              if (typeof tempRegistryPaperwork === "string") {
                                return tempRegistryPaperwork?.split("/")[1];
                              } else if (tempRegistryPaperwork?.[0]) {
                                return tempRegistryPaperwork[0].name;
                              } else {
                                return "No file uploaded";
                              }
                            })()}
                            readOnly
                            disabled
                          />
                        </CInputGroup>
                      </CFormLabel>
                      <CFormInput
                        id="sire_grand_sire_paperwork"
                        type="file"
                        accept=".pdf,.doc,.docx,.jpeg,.jpg"
                        style={{ visibility: "hidden", display: "none" }}
                        invalid={!!errors?.sire_grand_sire_paperwork}
                        {...register("sire_grand_sire_paperwork", {
                          validate: {
                            fileFormat: (value) =>
                              !value ||
                              handleFileExtensionCheck(value) ||
                              "Invalid file format",
                            fileSize: (value) =>
                              !value ||
                              handleFileSizeCheck(value) ||
                              "File size exceeds limit",
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      {errors?.sire_grand_sire_paperwork && (
                        <CFormFeedback invalid={true}>
                          {errors?.sire_grand_sire_paperwork?.message}
                        </CFormFeedback>
                      )}
                    </CRow>

                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Grand Dam (Sire) Name"
                          placeholder="Grand Dam (Sire) Name"
                          autoComplete="text"
                          invalid={!!errors?.sire_grand_dam_name}
                          {...register("sire_grand_dam_name", {
                            onChange: (e) => {
                              trigger("sire_grand_dam_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_grand_dam_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_grand_dam_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Grand Dam (Sire) ISHA Registration"
                          placeholder="Grand Dam (Sire) Reg #"
                          autoComplete="text"
                          invalid={!!errors?.sire_grand_dam_reg}
                          {...register("sire_grand_dam_reg", {
                            onChange: (e) => {
                              trigger("sire_grand_dam_reg");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_grand_dam_reg && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_grand_dam_reg?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Grand Dam (Sire) Other Reg Name"
                          placeholder="Grand Dam (Sire) Other Reg Name"
                          autoComplete="text"
                          invalid={!!errors?.sire_grand_dam_other_name}
                          {...register("sire_grand_dam_other_name", {
                            onChange: (e) => {
                              trigger("sire_grand_dam_other_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_grand_dam_other_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_grand_dam_other_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Grand Dam (Sire) Other Reg Number"
                          placeholder="Grand Dam (Sire) Other Reg Number"
                          autoComplete="text"
                          invalid={!!errors?.sire_grand_dam_other_number}
                          {...register("sire_grand_dam_other_number", {
                            onChange: (e) => {
                              trigger("sire_grand_dam_other_number");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_grand_dam_other_number && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_grand_dam_other_number?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CFormLabel>
                        Grand Dam (Sire) Registration Paperwork{" "}
                      </CFormLabel>
                      <CFormLabel
                        htmlFor={"sire_grand_dam_paperwork"}
                        className="w-100"
                      >
                        <CInputGroup>
                          <CInputGroupText>Choose File</CInputGroupText>
                          <CFormInput
                            type="text"
                            className="bg-transparent"
                            value={(() => {
                              const tempRegistryPaperwork = watch(
                                "sire_grand_dam_paperwork"
                              );
                              if (typeof tempRegistryPaperwork === "string") {
                                return tempRegistryPaperwork?.split("/")[1];
                              } else if (tempRegistryPaperwork?.[0]) {
                                return tempRegistryPaperwork[0].name;
                              } else {
                                return "No file uploaded";
                              }
                            })()}
                            readOnly
                            disabled
                          />
                        </CInputGroup>
                      </CFormLabel>
                      <CFormInput
                        id="sire_grand_dam_paperwork"
                        type="file"
                        accept=".pdf,.doc,.docx,.jpeg,.jpg"
                        style={{ visibility: "hidden", display: "none" }}
                        invalid={!!errors?.sire_grand_dam_paperwork}
                        {...register("sire_grand_dam_paperwork", {
                          validate: {
                            fileFormat: (value) =>
                              !value ||
                              handleFileExtensionCheck(value) ||
                              "Invalid file format",
                            fileSize: (value) =>
                              !value ||
                              handleFileSizeCheck(value) ||
                              "File size exceeds limit",
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      {errors?.sire_grand_dam_paperwork && (
                        <CFormFeedback invalid={true}>
                          {errors?.sire_grand_dam_paperwork?.message}
                        </CFormFeedback>
                      )}
                    </CRow>

                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Great-Grand Sire (Sire) Name"
                          placeholder="Great-Grand Sire (Sire) Name"
                          autoComplete="text"
                          invalid={!!errors?.sire_great_grand_sire_name}
                          {...register("sire_great_grand_sire_name", {
                            onChange: (e) => {
                              trigger("sire_great_grand_sire_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_great_grand_sire_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_great_grand_sire_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Great-Grand Sire (Sire) Registration"
                          placeholder="Great-Grand Sire (Sire) Reg #"
                          autoComplete="text"
                          invalid={!!errors?.sire_great_grand_sire_reg}
                          {...register("sire_great_grand_sire_reg", {
                            onChange: (e) => {
                              trigger("sire_great_grand_sire_reg");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_great_grand_sire_reg && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_great_grand_sire_reg?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Great-Grand Sire(Sire) Other Reg Name"
                          placeholder="Great-Grand Sire(Sire) Other Reg Name"
                          autoComplete="text"
                          invalid={!!errors?.sire_great_grand_sire_other_name}
                          {...register("sire_great_grand_sire_other_name", {
                            onChange: (e) => {
                              trigger("sire_great_grand_sire_other_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_great_grand_sire_other_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_great_grand_sire_other_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Great-Grand Sire(Sire) Other Reg Number"
                          placeholder="Great-Grand Sire(Sire) Other Reg Number"
                          autoComplete="text"
                          invalid={!!errors?.sire_great_grand_sire_other_number}
                          {...register("sire_great_grand_sire_other_number", {
                            onChange: (e) => {
                              trigger("sire_great_grand_sire_other_number");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_great_grand_sire_other_number && (
                          <CFormFeedback invalid={true}>
                            {
                              errors?.sire_great_grand_sire_other_number
                                ?.message
                            }
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CFormLabel>
                        Great Grand Sire (Sire) Registration Paperwork{" "}
                      </CFormLabel>
                      <CFormLabel
                        htmlFor={"sire_great_grand_sire_paperwork"}
                        className="w-100"
                      >
                        <CInputGroup>
                          <CInputGroupText>Choose File</CInputGroupText>
                          <CFormInput
                            type="text"
                            className="bg-transparent"
                            value={(() => {
                              const tempRegistryPaperwork = watch(
                                "sire_great_grand_sire_paperwork"
                              );
                              if (typeof tempRegistryPaperwork === "string") {
                                return tempRegistryPaperwork?.split("/")[1];
                              } else if (tempRegistryPaperwork?.[0]) {
                                return tempRegistryPaperwork[0].name;
                              } else {
                                return "No file uploaded";
                              }
                            })()}
                            readOnly
                            disabled
                          />
                        </CInputGroup>
                      </CFormLabel>
                      <CFormInput
                        id="sire_great_grand_sire_paperwork"
                        type="file"
                        accept=".pdf,.doc,.docx,.jpeg,.jpg"
                        style={{ visibility: "hidden", display: "none" }}
                        invalid={!!errors?.sire_great_grand_sire_paperwork}
                        {...register("sire_great_grand_sire_paperwork", {
                          validate: {
                            fileFormat: (value) =>
                              !value ||
                              handleFileExtensionCheck(value) ||
                              "Invalid file format",
                            fileSize: (value) =>
                              !value ||
                              handleFileSizeCheck(value) ||
                              "File size exceeds limit",
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      {errors?.sire_great_grand_sire_paperwork && (
                        <CFormFeedback invalid={true}>
                          {errors?.sire_great_grand_sire_paperwork?.message}
                        </CFormFeedback>
                      )}
                    </CRow>

                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Great-Grand Dam (Sire) Name"
                          placeholder="Great-Grand Dam (Sire) Name"
                          autoComplete="text"
                          invalid={!!errors?.sire_great_grand_dam_name}
                          {...register("sire_great_grand_dam_name", {
                            onChange: (e) => {
                              trigger("sire_great_grand_dam_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_great_grand_dam_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_great_grand_dam_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Great-Grand Dam (Sire) Registration"
                          placeholder="Great-Grand Dam (Sire) Reg #"
                          autoComplete="text"
                          invalid={!!errors?.sire_great_grand_dam_reg}
                          {...register("sire_great_grand_dam_reg", {
                            onChange: (e) => {
                              trigger("sire_great_grand_dam_reg");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_great_grand_dam_reg && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_great_grand_dam_reg?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Great-Grand Dam (Sire) Other Reg Name"
                          placeholder="Great-Grand Dam (Sire) Other Reg Name"
                          autoComplete="text"
                          invalid={!!errors?.sire_great_grand_dam_other_name}
                          {...register("sire_great_grand_dam_other_name", {
                            onChange: (e) => {
                              trigger("sire_great_grand_dam_other_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_great_grand_dam_other_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_great_grand_dam_other_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Great-Grand Dam (Sire) Other Reg Number"
                          placeholder="Great-Grand Dam (Sire) Other Reg Number"
                          autoComplete="text"
                          invalid={!!errors?.sire_great_grand_dam_other_number}
                          {...register("sire_great_grand_dam_other_number", {
                            onChange: (e) => {
                              trigger("sire_great_grand_dam_other_number");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.sire_great_grand_dam_other_number && (
                          <CFormFeedback invalid={true}>
                            {errors?.sire_great_grand_dam_other_number?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CFormLabel>
                        Great Grand Dam (Sire) Registration Paperwork{" "}
                      </CFormLabel>
                      <CFormLabel
                        htmlFor={"sire_great_grand_dam_paperwork"}
                        className="w-100"
                      >
                        <CInputGroup>
                          <CInputGroupText>Choose File</CInputGroupText>
                          <CFormInput
                            type="text"
                            className="bg-transparent"
                            value={(() => {
                              const tempRegistryPaperwork = watch(
                                "sire_great_grand_dam_paperwork"
                              );
                              if (typeof tempRegistryPaperwork === "string") {
                                return tempRegistryPaperwork?.split("/")[1];
                              } else if (tempRegistryPaperwork?.[0]) {
                                return tempRegistryPaperwork[0].name;
                              } else {
                                return "No file uploaded";
                              }
                            })()}
                            readOnly
                            disabled
                          />
                        </CInputGroup>
                      </CFormLabel>
                      <CFormInput
                        id="sire_great_grand_dam_paperwork"
                        type="file"
                        accept=".pdf,.doc,.docx,.jpeg,.jpg"
                        style={{ visibility: "hidden", display: "none" }}
                        invalid={!!errors?.sire_great_grand_dam_paperwork}
                        {...register("sire_great_grand_dam_paperwork", {
                          validate: {
                            fileFormat: (value) =>
                              !value ||
                              handleFileExtensionCheck(value) ||
                              "Invalid file format",
                            fileSize: (value) =>
                              !value ||
                              handleFileSizeCheck(value) ||
                              "File size exceeds limit",
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      {errors?.sire_great_grand_dam_paperwork && (
                        <CFormFeedback invalid={true}>
                          {errors?.sire_great_grand_dam_paperwork?.message}
                        </CFormFeedback>
                      )}
                    </CRow>
                  </div>
                </>
              )}

              {!Sire && (
                <>
                  <div>
                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Dam Name"
                          placeholder="Dam Name"
                          autoComplete="text"
                          invalid={!!errors?.dam_name}
                          id="sire1"
                          {...register("dam_name", {
                            onChange: (e) => {
                              trigger("dam_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Dam Registration"
                          placeholder="Dam Reg #"
                          autoComplete="text"
                          invalid={!!errors?.dam_number}
                          {...register("dam_number", {
                            onChange: (e) => {
                              trigger("dam_number");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_number && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_number?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Dam Other Registration Name"
                          placeholder="Dam Other Registration Name"
                          autoComplete="text"
                          invalid={!!errors?.dam_other_reg_name}
                          {...register("dam_other_reg_name", {
                            onChange: (e) => {
                              trigger("dam_other_reg_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_other_reg_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_other_reg_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Dam Other Registration Number"
                          placeholder="Dam Other Registration Number"
                          autoComplete="text"
                          invalid={!!errors?.dam_other_reg_number}
                          {...register("dam_other_reg_number", {
                            onChange: (e) => {
                              trigger("dam_other_reg_number");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_other_reg_number && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_other_reg_number?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CFormLabel>Dam Registration Paperwork </CFormLabel>
                      <CFormLabel htmlFor={"dam_paperwork"} className="w-100">
                        <CInputGroup>
                          <CInputGroupText>Choose File</CInputGroupText>
                          <CFormInput
                            type="text"
                            className="bg-transparent"
                            value={(() => {
                              const tempRegistryPaperwork =
                                watch("dam_paperwork");
                              if (typeof tempRegistryPaperwork === "string") {
                                return tempRegistryPaperwork?.split("/")[1];
                              } else if (tempRegistryPaperwork?.[0]) {
                                return tempRegistryPaperwork[0].name;
                              } else {
                                return "No file uploaded";
                              }
                            })()}
                            readOnly
                            disabled
                          />
                        </CInputGroup>
                      </CFormLabel>
                      <CFormInput
                        id="dam_paperwork"
                        type="file"
                        accept=".pdf,.doc,.docx,.jpeg,.jpg"
                        style={{ visibility: "hidden", display: "none" }}
                        invalid={!!errors?.dam_paperwork}
                        {...register("dam_paperwork", {
                          validate: {
                            fileFormat: (value) =>
                              !value ||
                              handleFileExtensionCheck(value) ||
                              "Invalid file format",
                            fileSize: (value) =>
                              !value ||
                              handleFileSizeCheck(value) ||
                              "File size exceeds limit",
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      {errors?.dam_paperwork && (
                        <CFormFeedback invalid={true}>
                          {errors?.dam_paperwork?.message}
                        </CFormFeedback>
                      )}
                    </CRow>

                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Grand Sire (Dam) Name"
                          placeholder="Grand Sire (Dam) Name"
                          autoComplete="text"
                          invalid={!!errors?.dam_grand_sire_name}
                          {...register("dam_grand_sire_name", {
                            onChange: (e) => {
                              trigger("dam_grand_sire_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_grand_sire_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_grand_sire_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Grand Sire (Dam) Registration"
                          placeholder="Grand Sire (Dam) Reg #"
                          autoComplete="text"
                          invalid={!!errors?.dam_grand_sire_reg}
                          {...register("dam_grand_sire_reg", {
                            onChange: (e) => {
                              trigger("dam_grand_sire_reg");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_grand_sire_reg && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_grand_sire_reg?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Grand Sire (Dam) Other Reg Name"
                          placeholder="Grand Sire (Dam) Other Reg Name"
                          autoComplete="text"
                          invalid={!!errors?.dam_grand_sire_other_name}
                          {...register("dam_grand_sire_other_name", {
                            onChange: (e) => {
                              trigger("dam_grand_sire_other_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_grand_sire_other_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_grand_sire_other_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Grand Sire (Dam) Other Reg Number"
                          placeholder="Grand Sire (Dam) Other Reg Number"
                          autoComplete="text"
                          invalid={!!errors?.dam_grand_sire_other_number}
                          {...register("dam_grand_sire_other_number", {
                            onChange: (e) => {
                              trigger("dam_grand_sire_other_number");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_grand_sire_other_number && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_grand_sire_other_number?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CFormLabel>
                        Grand Sire (Dam) Registration Paperwork{" "}
                      </CFormLabel>
                      <CFormLabel
                        htmlFor={"dam_grand_sire_paperwork"}
                        className="w-100"
                      >
                        <CInputGroup>
                          <CInputGroupText>Choose File</CInputGroupText>
                          <CFormInput
                            type="text"
                            className="bg-transparent"
                            value={(() => {
                              const tempRegistryPaperwork = watch(
                                "dam_grand_sire_paperwork"
                              );
                              if (typeof tempRegistryPaperwork === "string") {
                                return tempRegistryPaperwork?.split("/")[1];
                              } else if (tempRegistryPaperwork?.[0]) {
                                return tempRegistryPaperwork[0].name;
                              } else {
                                return "No file uploaded";
                              }
                            })()}
                            readOnly
                            disabled
                          />
                        </CInputGroup>
                      </CFormLabel>
                      <CFormInput
                        id="dam_grand_sire_paperwork"
                        type="file"
                        accept=".pdf,.doc,.docx,.jpeg,.jpg"
                        style={{ visibility: "hidden", display: "none" }}
                        invalid={!!errors?.dam_grand_sire_paperwork}
                        {...register("dam_grand_sire_paperwork", {
                          validate: {
                            fileFormat: (value) =>
                              !value ||
                              handleFileExtensionCheck(value) ||
                              "Invalid file format",
                            fileSize: (value) =>
                              !value ||
                              handleFileSizeCheck(value) ||
                              "File size exceeds limit",
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      {errors?.dam_grand_sire_paperwork && (
                        <CFormFeedback invalid={true}>
                          {errors?.dam_grand_sire_paperwork?.message}
                        </CFormFeedback>
                      )}
                    </CRow>

                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Grand Dam (Dam) Name"
                          placeholder="Grand Dam (Dam) Name"
                          autoComplete="text"
                          invalid={!!errors?.dam_grand_dam_name}
                          {...register("dam_grand_dam_name", {
                            onChange: (e) => {
                              trigger("dam_grand_dam_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_grand_dam_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_grand_dam_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Grand Dam (Dam) Registration"
                          placeholder="Grand Dam (Dam) Reg #"
                          autoComplete="text"
                          invalid={!!errors?.dam_grand_dam_reg}
                          {...register("dam_grand_dam_reg", {
                            onChange: (e) => {
                              trigger("dam_grand_dam_reg");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_grand_dam_reg && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_grand_dam_reg?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Grand Dam (Dam) Other Reg Name"
                          placeholder="Grand Dam (Dam) Other Reg Name"
                          autoComplete="text"
                          invalid={!!errors?.dam_grand_dam_other_name}
                          {...register("dam_grand_dam_other_name", {
                            onChange: (e) => {
                              trigger("dam_grand_dam_other_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_grand_dam_other_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_grand_dam_other_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Grand Dam (Dam) Other Reg Number"
                          placeholder="Grand Dam (Dam) Other Reg Number"
                          autoComplete="text"
                          invalid={!!errors?.dam_grand_dam_other_number}
                          {...register("dam_grand_dam_other_number", {
                            onChange: (e) => {
                              trigger("dam_grand_dam_other_number");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_grand_dam_other_number && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_grand_dam_other_number?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CFormLabel>
                        Grand Dam (Dam) Registration Paperwork{" "}
                      </CFormLabel>
                      <CFormLabel
                        htmlFor={"dam_grand_dam_paperwork"}
                        className="w-100"
                      >
                        <CInputGroup>
                          <CInputGroupText>Choose File</CInputGroupText>
                          <CFormInput
                            type="text"
                            className="bg-transparent"
                            value={(() => {
                              const tempRegistryPaperwork = watch(
                                "dam_grand_dam_paperwork"
                              );
                              if (typeof tempRegistryPaperwork === "string") {
                                return tempRegistryPaperwork?.split("/")[1];
                              } else if (tempRegistryPaperwork?.[0]) {
                                return tempRegistryPaperwork[0].name;
                              } else {
                                return "No file uploaded";
                              }
                            })()}
                            readOnly
                            disabled
                          />
                        </CInputGroup>
                      </CFormLabel>
                      <CFormInput
                        id="dam_grand_dam_paperwork"
                        type="file"
                        accept=".pdf,.doc,.docx,.jpeg,.jpg"
                        style={{ visibility: "hidden", display: "none" }}
                        invalid={!!errors?.dam_grand_dam_paperwork}
                        {...register("dam_grand_dam_paperwork", {
                          validate: {
                            fileFormat: (value) =>
                              !value ||
                              handleFileExtensionCheck(value) ||
                              "Invalid file format",
                            fileSize: (value) =>
                              !value ||
                              handleFileSizeCheck(value) ||
                              "File size exceeds limit",
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      {errors?.dam_grand_dam_paperwork && (
                        <CFormFeedback invalid={true}>
                          {errors?.dam_grand_dam_paperwork?.message}
                        </CFormFeedback>
                      )}
                    </CRow>

                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Great-Grand Sire (Dam) Name"
                          placeholder="Great-Grand Sire (Dam) Name"
                          autoComplete="text"
                          invalid={!!errors?.dam_great_grand_sire_name}
                          {...register("dam_great_grand_sire_name", {
                            onChange: (e) => {
                              trigger("dam_great_grand_sire_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_great_grand_sire_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_great_grand_sire_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Great-Grand Sire (Dam) Registration"
                          placeholder="Great-Grand Sire (Dam) Reg #"
                          autoComplete="text"
                          invalid={!!errors?.dam_great_grand_sire_reg}
                          {...register("dam_great_grand_sire_reg", {
                            onChange: (e) => {
                              trigger("dam_great_grand_sire_reg");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_great_grand_sire_reg && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_great_grand_sire_reg?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Great-Grand Sire(Dam) Other Reg Name"
                          placeholder="Great-Grand Sire(Dam) Other Reg Name"
                          autoComplete="text"
                          invalid={!!errors?.dam_great_grand_sire_other_name}
                          {...register("dam_great_grand_sire_other_name", {
                            onChange: (e) => {
                              trigger("dam_great_grand_sire_other_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_great_grand_sire_other_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_great_grand_sire_other_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Great-Grand Sire(Dam) Other Reg Number"
                          placeholder="Great-Grand Sire(Dam) Other Reg Number"
                          autoComplete="text"
                          invalid={!!errors?.dam_great_grand_sire_other_number}
                          {...register("dam_great_grand_sire_other_number", {
                            onChange: (e) => {
                              trigger("dam_great_grand_sire_other_number");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_great_grand_sire_other_number && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_great_grand_sire_other_number?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CFormLabel>
                        Great Grand Sire (Dam) Registration Paperwork{" "}
                      </CFormLabel>
                      <CFormLabel
                        htmlFor={"dam_great_grand_sire_paperwork"}
                        className="w-100"
                      >
                        <CInputGroup>
                          <CInputGroupText>Choose File</CInputGroupText>
                          <CFormInput
                            type="text"
                            className="bg-transparent"
                            value={(() => {
                              const tempRegistryPaperwork = watch(
                                "dam_great_grand_sire_paperwork"
                              );
                              if (typeof tempRegistryPaperwork === "string") {
                                return tempRegistryPaperwork?.split("/")[1];
                              } else if (tempRegistryPaperwork?.[0]) {
                                return tempRegistryPaperwork[0].name;
                              } else {
                                return "No file uploaded";
                              }
                            })()}
                            readOnly
                            disabled
                          />
                        </CInputGroup>
                      </CFormLabel>
                      <CFormInput
                        id="dam_great_grand_sire_paperwork"
                        type="file"
                        accept=".pdf,.doc,.docx,.jpeg,.jpg"
                        style={{ visibility: "hidden", display: "none" }}
                        invalid={!!errors?.dam_great_grand_sire_paperwork}
                        {...register("dam_great_grand_sire_paperwork", {
                          validate: {
                            fileFormat: (value) =>
                              !value ||
                              handleFileExtensionCheck(value) ||
                              "Invalid file format",
                            fileSize: (value) =>
                              !value ||
                              handleFileSizeCheck(value) ||
                              "File size exceeds limit",
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      {errors?.dam_great_grand_sire_paperwork && (
                        <CFormFeedback invalid={true}>
                          {errors?.dam_great_grand_sire_paperwork?.message}
                        </CFormFeedback>
                      )}
                    </CRow>

                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Great-Grand Dam (Dam) Name"
                          placeholder="Great-Grand Dam (Dam) Name"
                          autoComplete="text"
                          invalid={!!errors?.dam_great_grand_dam_name}
                          {...register("dam_great_grand_dam_name", {
                            onChange: (e) => {
                              trigger("dam_great_grand_dam_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_great_grand_dam_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_great_grand_dam_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Great-Grand Dam (Dam) Registration"
                          placeholder="Great-Grand Dam (Dam) Reg #"
                          autoComplete="text"
                          invalid={!!errors?.dam_great_grand_dam_reg}
                          {...register("dam_great_grand_dam_reg", {
                            onChange: (e) => {
                              trigger("dam_great_grand_dam_reg");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_great_grand_dam_reg && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_great_grand_dam_reg?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CCol>
                        <CFormInput
                          label="Great-Grand Dam (Dam) Other Reg Name"
                          placeholder="Great-Grand Dam (Dam) Other Reg Name"
                          autoComplete="text"
                          invalid={!!errors?.dam_great_grand_dam_other_name}
                          {...register("dam_great_grand_dam_other_name", {
                            onChange: (e) => {
                              trigger("dam_great_grand_dam_other_name");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_great_grand_dam_other_name && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_great_grand_dam_other_name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                      <CCol>
                        <CFormInput
                          label="Great-Grand Dam (Dam) Other Reg Number"
                          placeholder="Great-Grand Dam (Dam) Other Reg Number"
                          autoComplete="text"
                          invalid={!!errors?.dam_great_grand_dam_other_number}
                          {...register("dam_great_grand_dam_other_number", {
                            onChange: (e) => {
                              trigger("dam_great_grand_dam_other_number");
                            },
                          })}
                          disabled={mode === "view"}
                        />
                        {errors?.dam_great_grand_dam_other_number && (
                          <CFormFeedback invalid={true}>
                            {errors?.dam_great_grand_dam_other_number?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CFormLabel>
                        Great Grand Dam (Dam) Registration Paperwork{" "}
                      </CFormLabel>
                      <CFormLabel
                        htmlFor={"dam_great_grand_dam_paperwork"}
                        className="w-100"
                      >
                        <CInputGroup>
                          <CInputGroupText>Choose File</CInputGroupText>
                          <CFormInput
                            type="text"
                            className="bg-transparent"
                            value={(() => {
                              const tempRegistryPaperwork = watch(
                                "dam_great_grand_dam_paperwork"
                              );
                              if (typeof tempRegistryPaperwork === "string") {
                                return tempRegistryPaperwork?.split("/")[1];
                              } else if (tempRegistryPaperwork?.[0]) {
                                return tempRegistryPaperwork[0].name;
                              } else {
                                return "No file uploaded";
                              }
                            })()}
                            readOnly
                            disabled
                          />
                        </CInputGroup>
                      </CFormLabel>
                      <CFormInput
                        id="dam_great_grand_dam_paperwork"
                        type="file"
                        accept=".pdf,.doc,.docx,.jpeg,.jpg"
                        style={{ visibility: "hidden", display: "none" }}
                        invalid={!!errors?.dam_great_grand_dam_paperwork}
                        {...register("dam_great_grand_dam_paperwork", {
                          validate: {
                            fileFormat: (value) =>
                              !value ||
                              handleFileExtensionCheck(value) ||
                              "Invalid file format",
                            fileSize: (value) =>
                              !value ||
                              handleFileSizeCheck(value) ||
                              "File size exceeds limit",
                          },
                        })}
                        disabled={mode === "view"}
                      />
                      {errors?.dam_great_grand_dam_paperwork && (
                        <CFormFeedback invalid={true}>
                          {errors?.dam_great_grand_dam_paperwork?.message}
                        </CFormFeedback>
                      )}
                    </CRow>
                  </div>
                </>
              )}
            </div>

            <div className="mt-3">
              <CFormLabel>Markings:</CFormLabel>
              <CRow className="mt-3">
                <CCol md={6}>
                  <CFormLabel>Color *</CFormLabel>
                  <CFormInput
                    type="text"
                    name="color"
                    invalid={!!errors?.color}
                    {...register("color", {
                      onChange: (e) => {
                        trigger("color");
                      },
                    })}
                    disabled={mode === "view"}
                  />
                  {errors?.color && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.color?.message}
                    </CFormFeedback>
                  )}
                </CCol>

                <CCol md={6}>
                  <CFormLabel>Pattern *</CFormLabel>
                  <CFormInput
                    type="text"
                    name="pattern"
                    invalid={!!errors?.pattern}
                    {...register("pattern", {
                      onChange: (e) => {
                        trigger("pattern");
                      },
                    })}
                    disabled={mode === "view"}
                  />
                  {errors?.pattern && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.pattern?.message}
                    </CFormFeedback>
                  )}
                </CCol>
                <CCol md={6}>
                  <CFormLabel>Left side</CFormLabel>
                  <CFormInput
                    type="text"
                    name="othermarkings_left"
                    invalid={!!errors?.othermarkings_left}
                    {...register("othermarkings_left", {
                      onChange: (e) => {
                        trigger("othermarkings_left");
                      },
                    })}
                    disabled={mode === "view"}
                  />
                  {errors?.othermarkings_left && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.othermarkings_left?.message}
                    </CFormFeedback>
                  )}
                </CCol>
                <CCol md={6}>
                  <CFormLabel>Right side</CFormLabel>
                  <CFormInput
                    type="text"
                    name="othermarkings_right"
                    invalid={!!errors?.othermarkings_right}
                    {...register("othermarkings_right", {
                      onChange: (e) => {
                        trigger("othermarkings_right");
                      },
                    })}
                    disabled={mode === "view"}
                  />
                  {errors?.othermarkings_right && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.othermarkings_right?.message}
                    </CFormFeedback>
                  )}
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md={6}>
                  <CFormLabel>Face</CFormLabel>
                  <CFormInput
                    type="text"
                    name="othermarkings_face"
                    invalid={!!errors?.othermarkings_face}
                    {...register("othermarkings_face", {
                      onChange: (e) => {
                        trigger("othermarkings_face");
                      },
                    })}
                    disabled={mode === "view"}
                  />
                  {errors?.othermarkings_face && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.othermarkings_face?.message}
                    </CFormFeedback>
                  )}
                </CCol>
                <CCol md={6}>
                  <CFormLabel>Upper Lip and Chin</CFormLabel>
                  <CFormInput
                    type="text"
                    name="othermarkings_chin"
                    invalid={!!errors?.othermarkings_chin}
                    {...register("othermarkings_chin", {
                      onChange: (e) => {
                        trigger("othermarkings_chin");
                      },
                    })}
                    disabled={mode === "view"}
                  />
                  {errors?.othermarkings_chin && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.othermarkings_chin?.message}
                    </CFormFeedback>
                  )}
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md={6}>
                  <CFormLabel>Front Legs</CFormLabel>
                  <CFormInput
                    type="text"
                    name="othermarkings_frontLeg"
                    invalid={!!errors?.othermarkings_frontLeg}
                    {...register("othermarkings_frontLeg", {
                      onChange: (e) => {
                        trigger("othermarkings_frontLeg");
                      },
                    })}
                    disabled={mode === "view"}
                  />
                  {errors?.othermarkings_frontLeg && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.othermarkings_frontLeg?.message}
                    </CFormFeedback>
                  )}
                </CCol>
                <CCol md={6}>
                  <CFormLabel>Hind Legs</CFormLabel>
                  <CFormInput
                    type="text"
                    name="othermarkings_hindLeg"
                    invalid={!!errors?.othermarkings_hindLeg}
                    {...register("othermarkings_hindLeg", {
                      onChange: (e) => {
                        trigger("othermarkings_hindLeg");
                      },
                    })}
                    disabled={mode === "view"}
                  />
                  {errors?.othermarkings_hindLeg && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.othermarkings_hindLeg?.message}
                    </CFormFeedback>
                  )}
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol md={6}>
                  <CFormLabel>Main Color</CFormLabel>
                  <CFormInput
                    type="text"
                    name="othermarkings_maneColor"
                    invalid={!!errors?.othermarkings_maneColor}
                    {...register("othermarkings_maneColor", {
                      onChange: (e) => {
                        trigger("othermarkings_maneColor");
                      },
                    })}
                    disabled={mode === "view"}
                  />
                  {errors?.othermarkings_maneColor && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.othermarkings_maneColor?.message}
                    </CFormFeedback>
                  )}
                </CCol>
                <CCol md={6}>
                  <CFormLabel>Tail Color</CFormLabel>
                  <CFormInput
                    type="text"
                    name="othermarkings_tailColor"
                    invalid={!!errors?.othermarkings_tailColor}
                    {...register("othermarkings_tailColor", {
                      onChange: (e) => {
                        trigger("othermarkings_tailColor");
                      },
                    })}
                    disabled={mode === "view"}
                  />
                  {errors?.othermarkings_tailColor && (
                    <CFormFeedback className="d-block" invalid={true}>
                      {errors?.othermarkings_tailColor?.message}
                    </CFormFeedback>
                  )}
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CFormLabel>
                  Upload Marking Documents (Front, Both Sides, Back (minimum 4 Files)) *
                </CFormLabel>
                <CFormLabel htmlFor={"markingDocuments"} className="w-100">
                  <CInputGroup>
                    <CInputGroupText>Choose File</CInputGroupText>
                    <CFormInput
                      type="text"
                      className="bg-transparent"
                      value={(() => {
                        const tempMarkingDocs = watch("markingDocuments"); // Watch for changes
                        if (
                          mode === "view" &&
                          typeof tempMarkingDocs === "number"
                        ) {
                          return `${tempMarkingDocs} file(s)`;
                        }

                        if (!tempMarkingDocs) {
                          return "No file uploaded";
                        }else if (typeof tempMarkingDocs === "string") {
                          // Handle single file upload with existing file as a string
                          return tempMarkingDocs?.split("/").pop();
                        }else if (tempMarkingDocs.length > 0) {
                          // Handle multiple file uploads
                          if (tempMarkingDocs.length === 1) {
                            return tempMarkingDocs[0].name; // Show single file name
                          }
                          return `${tempMarkingDocs.length} file(s) selected`; // Show file count
                        }

                        return "No file uploaded"; // Default state
                      })()}
                      readOnly
                      disabled
                    />
                  </CInputGroup>
                </CFormLabel>

                <CFormInput
                  id="markingDocuments"
                  type="file"
                  accept=".png,.jpeg,.jpg"
                  style={{ visibility: "hidden", display: "none" }}
                  invalid={!!errors?.markingDocuments}
                  {...register("markingDocuments", {
                    required: "marking Documents is required",
                    onChange: (e) => {
                      const files = Array.from(e.target.files); // Convert FileList to array
                      setValue("markingDocuments", files); // Update form state
                      trigger("markingDocuments");
                    },
                    validate: {
                      fileFormat: (value) =>
                        !value ||
                        handleFileExtensionCheck(value) ||
                        "Invalid file format",
                      fileSize: (value) =>
                        !value ||
                        handleFileSizeCheck(value) ||
                        "File size exceeds limit",
                      minimumFiles: (value) =>
                        value && value.length >= 4
                          ? true
                          : "At least 4 files are required",
                    },
                  })}
                  multiple
                  disabled={mode === "view"}
                />
                {errors?.markingDocuments && (
                  <CFormFeedback invalid={true} className="d-block">
                    {errors?.markingDocuments?.message}
                  </CFormFeedback>
                )}
                <div>Accept only .png, .jpeg, .jpg</div>
              </CRow>
            </div>

            <div className="mt-3">
              <CFormLabel>Owner Information:</CFormLabel>
              <CRow className="mt-3">
                <CCol>
                  <CFormLabel>Owner Type *</CFormLabel>
                  <div className="d-flex gap-3 items-center">
                    {["Joint", "Single"].map((type) => {
                      return (
                        <CFormCheck
                          type="radio"
                          name="ownerType"
                          id={`ownerType${type}`}
                          label={type}
                          value={type}
                          invalid={!!errors?.ownerType}
                          {...register("ownerType", {
                            onChange: (e) => {
                              trigger("ownerType");
                              if (e.target.value === "Joint") {
                                setValue("owners", [
                                  {
                                    memberNum: "",
                                    name: "",
                                    address: "",
                                    city: "",
                                    state: "",
                                    zip: "",
                                  },
                                  {
                                    memberNum: "",
                                    name: "",
                                    address: "",
                                    city: "",
                                    state: "",
                                    zip: "",
                                  },
                                ]);
                              } else {
                                setValue("owners", [
                                  {
                                    memberNum: "",
                                    name: "",
                                    address: "",
                                    city: "",
                                    state: "",
                                    zip: "",
                                  },
                                ]);
                              }
                            },
                          })}
                          disabled={mode === "view"}
                        />
                      );
                    })}
                  </div>
                </CCol>
              </CRow>
              <div>
                {owners.map((owner, ind) => (
                  <div key={ind}>
                    <CRow className="mt-3">
                      <CCol md={6}>
                        <CFormInput
                          label={`ISHA Member ${ind + 1}#`}
                          placeholder={`ISHA Member ${ind + 1}#`}
                          autoComplete="text"
                          invalid={!!errors?.owners?.[ind]?.memberNum}
                          {...register(`owners.${ind}.memberNum`)}
                          disabled={mode === "view"}
                        />
                        {errors?.owners?.[ind]?.memberNum && (
                          <CFormFeedback invalid={true} className="d-block">
                            {errors?.owners?.[ind]?.memberNum?.message}
                          </CFormFeedback>
                        )}
                      </CCol>

                      <CCol md={6}>
                        <CFormInput
                          label={`Name ${ind + 1}#`}
                          placeholder={`Name ${ind + 1}#`}
                          autoComplete="text"
                          invalid={!!errors?.owners?.[ind]?.name}
                          {...register(`owners.${ind}.name`)}
                          disabled={mode === "view"}
                        />
                        {errors?.owners?.[ind]?.name && (
                          <CFormFeedback invalid={true} className="d-block">
                            {errors?.owners?.[ind]?.name?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CCol md={6}>
                        <CFormInput
                          label={`Address ${ind + 1}#`}
                          placeholder={`Address${ind + 1}#`}
                          autoComplete="text"
                          invalid={!!errors?.owners?.[ind]?.address}
                          {...register(`owners.${ind}.address`)}
                          disabled={mode === "view"}
                        />
                        {errors?.owners?.[ind]?.address && (
                          <CFormFeedback invalid={true} className="d-block">
                            {errors?.owners?.[ind]?.address?.message}
                          </CFormFeedback>
                        )}
                      </CCol>

                      <CCol md={6}>
                        <CFormInput
                          label={`City ${ind + 1}#`}
                          placeholder={`City ${ind + 1}#`}
                          autoComplete="text"
                          invalid={!!errors?.owners?.[ind]?.city}
                          {...register(`owners.${ind}.city`)}
                          disabled={mode === "view"}
                        />
                        {errors?.owners?.[ind]?.city && (
                          <CFormFeedback invalid={true} className="d-block">
                            {errors?.owners?.[ind]?.city?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mt-3">
                      <CCol md={6}>
                        <CFormInput
                          label={`State ${ind + 1}#`}
                          placeholder={`State ${ind + 1}#`}
                          autoComplete="text"
                          invalid={!!errors?.owners?.[ind]?.state}
                          {...register(`owners.${ind}.state`)}
                          disabled={mode === "view"}
                        />
                        {errors?.owners?.[ind]?.state && (
                          <CFormFeedback invalid={true} className="d-block">
                            {errors?.owners?.[ind]?.state?.message}
                          </CFormFeedback>
                        )}
                      </CCol>

                      <CCol md={6}>
                        <CFormInput
                          label={`Zip ${ind + 1}#`}
                          placeholder={`Zip ${ind + 1}#`}
                          autoComplete="text"
                          invalid={!!errors?.owners?.[ind]?.zip}
                          {...register(`owners.${ind}.zip`)}
                          disabled={mode === "view"}
                        />
                        {errors?.owners?.[ind]?.zip && (
                          <CFormFeedback invalid={true} className="d-block">
                            {errors?.owners?.[ind]?.zip?.message}
                          </CFormFeedback>
                        )}
                      </CCol>
                    </CRow>
                  </div>
                ))}
              </div>
            </div>

            <CCol className="mt-3">
              <CFormCheck
                id={"checkedOwnerInfo"}
                label={
                  "I hereby certify that all information on the registration application and/or parentage verification kit request is true and correct to my personal knowledge and agree that the association has the right to correct and/or cancel the registration certificate for cause under its rules and regulations."
                }
                name="checkedOwnerInfo"
                invalid={!!errors?.checkedOwnerInfo}
                {...register("checkedOwnerInfo", {
                  onChange: (e) => {
                    trigger("checkedOwnerInfo");
                  },
                })}
                disabled={mode === "view"}
              />
              {errors?.checkedOwnerInfo && (
                <CFormFeedback invalid={true} className="d-block">
                  {errors?.checkedOwnerInfo?.message}
                </CFormFeedback>
              )}
            </CCol>
            {
              mode !== 'edit' &&
              <CCol className="mt-3">
              <CFormLabel className="">Owner's Signature : </CFormLabel>
              <CButton
                color="dark"
                className="mx-3"
                onClick={() => setOpenOnwerSignature(true)}
                disabled={mode === "view"}
              >
                Open Signature Pad
              </CButton>
              <Controller
                name="ownerSignature"
                control={control}
                render={({ field }) => (
                  <>
                    {field.value && (
                      <img
                        src={URL.createObjectURL(field.value)}
                        alt="Owner Signature"
                        style={{ maxWidth: "100px", maxHeight: "50px" }}
                      />
                    )}
                  </>
                )}
                disabled={mode === "view"}
              />
              {openOnwerSignature && (
                <CModal
                  visible={openOnwerSignature}
                  onClose={() => setOpenOnwerSignature(false)}
                >
                  <CModalHeader>
                    <CModalTitle>Owner's Signature</CModalTitle>
                  </CModalHeader>
                  <CModalBody>
                    <div className="mt-3">
                      <ReactSignatureCanvas
                        ref={ownerSignatureRef}
                        penColor="black"
                        canvasProps={{
                          height: 200,
                          width: 400,
                          style: {
                            border: "1px solid black",
                            borderRadius: "5px",
                            backgroundColor: "#f9f9f9",
                          },
                        }}
                      />
                      <CModalFooter>
                        <div className="d-flex gap-3">
                          <CButton
                            color="secondary"
                            onClick={() =>
                              clearSignature(
                                ownerSignatureRef,
                                "ownerSignature"
                              )
                            }
                          >
                            Clear
                          </CButton>
                          <CButton
                            color="secondary"
                            onClick={() => {
                              saveSignature(
                                ownerSignatureRef,
                                "ownerSignature"
                              );
                              setOpenOnwerSignature(false);
                            }}
                          >
                            Save
                          </CButton>
                        </div>
                      </CModalFooter>
                    </div>
                  </CModalBody>
                </CModal>
              )}
              {errors?.ownerSignature && (
                <CFormFeedback className="d-block" invalid={true}>
                  {errors?.ownerSignature?.message}
                </CFormFeedback>
              )}
            </CCol>
            }
            {mode === "view" || mode === "edit" ? (
              ""
            ) : (
              <>
                <CCol className="mt-3">
                  <CFormLabel className="">Fees : </CFormLabel>
                  <CFormCheck
                    label={"Registration fee per horse : $30"}
                    name="registrationFee"
                    value={"30"}
                    invalid={!!errors?.fees?.registrationFee}
                    {...register(`fees.registrationFee`)}
                    checked
                    readOnly
                  />
                  <CFormCheck
                    id={"prepayDNAFeeAmount"}
                    label={"Prepay DNA Fee : $50"}
                    name="prepayDNAFeeAmount"
                    value={"50"}
                    invalid={!!errors?.fees?.prepayDNAFeeAmount}
                    {...register(`fees.prepayDNAFeeAmount`, {
                      onChange: (e) => {
                        trigger(`fees.prepayDNAFeeAmount`);
                        if (e.target.checked) {                          
                          setValue("fees.prepayDNAFee", true);
                          setValue("fees.prepayDNAFeeAmount", "50");
                        } else {
                          setValue("fees.prepayDNAFee", false);
                          setValue("fees.prepayDNAFeeAmount", 0);
                        }
                      },
                    })}
                  />
                  <CFormCheck
                    label={"Verification Fee : $20"}
                    name="Verification fee"
                    disabled
                    // checked={!!watch("verificationFee")}
                    checked={watch("performanceVerification") === "true"}
                    // {...register("fees.verification_fees")}
                  />
                </CCol>

                <CRow className="mt-3">
                  <CFormLabel className="">Fees : </CFormLabel>
                  <CRow className="">
                    <CCol>
                      <CFormTextarea
                        label="Billing Address"
                        rows={3}
                        invalid={!!errors?.billingInfo?.billingAddress}
                        {...register(`billingInfo.billingAddress`, {
                          onChange: (e) => {
                            trigger(`billingInfo.billingAddress`);
                          },
                        })}
                      ></CFormTextarea>
                      {errors?.billingInfo?.billingAddress && (
                        <CFormFeedback className="d-block" invalid={true}>
                          {errors?.billingInfo?.billingAddress?.message}
                        </CFormFeedback>
                      )}
                    </CCol>
                  </CRow>
                  <CRow className="mt-3">
                    <CCol md={6}>
                      <CFormInput
                        label={`Phone Number *`}
                        placeholder={`Phone Number`}
                        autoComplete="text"
                        invalid={!!errors?.billingInfo?.phoneNumber}
                        {...register(`billingInfo.phoneNumber`, {
                          onChange: (e) => {
                            trigger(`billingInfo.phoneNumber`);
                          },
                        })}
                      />
                      {errors?.billingInfo?.phoneNumber && (
                        <CFormFeedback className="d-block" invalid={true}>
                          {errors?.billingInfo?.phoneNumber?.message}
                        </CFormFeedback>
                      )}
                    </CCol>
                    <CCol md={6}>
                      <CFormInput
                        label={`Email *`}
                        placeholder={`Email`}
                        autoComplete="text"
                        invalid={!!errors?.billingInfo?.email}
                        {...register(`billingInfo.email`, {
                          onChange: (e) => {
                            trigger(`billingInfo.email`);
                          },
                        })}
                      />
                      {errors?.billingInfo?.email && (
                        <CFormFeedback className="d-block" invalid={true}>
                          {errors?.billingInfo?.email?.message}
                        </CFormFeedback>
                      )}
                    </CCol>
                  </CRow>
                  <CRow className="mt-3">
                    <CCol>
                      <CFormLabel className="">
                        Select Payment Method :{" "}
                      </CFormLabel>

                      <div className="d-flex items-center gap-3">
                        <CFormCheck
                          id={"Credit Card"}
                          type="radio"
                          label={"Credit Card"}
                          value="Credit Card"
                          invalid={
                            !!errors?.billingInfo?.paymentInfo?.paymentMethod
                          }
                          {...register(
                            `billingInfo.paymentInfo.paymentMethod`,
                            {
                              onChange: (e) => {
                                trigger(
                                  `billingInfo.paymentInfo.paymentMethod`
                                ); // Trigger validation for paymentMethod field
                              },
                            }
                          )}
                        />
                        <CFormCheck
                          id={"Bank"}
                          type="radio"
                          label={"Bank (ACH)"}
                          value="Bank"
                          invalid={
                            !!errors?.billingInfo?.paymentInfo?.paymentMethod
                          }
                          {...register(
                            `billingInfo.paymentInfo.paymentMethod`,
                            {
                              onChange: (e) => {
                                trigger(
                                  `billingInfo.paymentInfo.paymentMethod`
                                ); // Trigger validation for paymentMethod field
                              },
                            }
                          )}
                        />
                      </div>
                      {errors?.billingInfo?.paymentInfo?.paymentMethod && (
                        <CFormFeedback className="d-block" invalid={true}>
                          {
                            errors?.billingInfo?.paymentInfo?.paymentMethod
                              ?.message
                          }
                        </CFormFeedback>
                      )}
                    </CCol>
                    {watch("billingInfo.paymentInfo.paymentMethod") ==
                      "Credit Card" && (
                      <>
                        <CRow>
                          <CCol className="mt-3">
                            <CFormLabel>Name on Card</CFormLabel>
                            <div className="d-flex items-center gap-3">
                              {["MasterCard", "VISA", "Discover"].map(
                                (card) => (
                                  <CFormCheck
                                    id={card}
                                    type="radio"
                                    name="cardType"
                                    key={card}
                                    value={card}
                                    label={card}
                                    invalid={
                                      !!errors?.billingInfo?.paymentInfo
                                        ?.creditCard?.cardType
                                    }
                                    {...register(
                                      `billingInfo.paymentInfo.creditCard.cardType`,
                                      {
                                        onChange: (e) => {
                                          trigger(
                                            `billingInfo.paymentInfo.creditCard.cardType`
                                          ); // Trigger validation for paymentMethod field
                                        },
                                      }
                                    )}
                                  />
                                )
                              )}
                              {errors?.billingInfo?.paymentInfo?.creditCard
                                ?.cardType && (
                                <CFormFeedback
                                  className="d-block"
                                  invalid={true}
                                >
                                  {
                                    errors?.billingInfo?.paymentInfo?.creditCard
                                      ?.cardType?.message
                                  }
                                </CFormFeedback>
                              )}
                            </div>
                          </CCol>
                        </CRow>
                        <CRow className="mt-3">
                          <CCol>
                            <CFormLabel>Card Number</CFormLabel>
                            <Controller
                              name="billingInfo?.paymentInfo?.creditCard?.cardNumber"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <CardNumberElement
                                    {...field}
                                    onChange={(e) => {
                                      // field.onChange(e); // Ensure `onChange` is called for react-hook-form
                                      // trigger(
                                      //   "billingInfo?.paymentInfo?.creditCard?.cardNumber"
                                      // ); // Trigger validation
                                      if (e.complete) {
                                        // Card number is valid and complete
                                        clearErrors(
                                          "billingInfo.paymentInfo.creditCard.cardNumber"
                                        );
                                        field.onChange(e.value); // Store "Valid" or other placeholder value
                                      } else if (e.error) {
                                        // Handle validation errors from Stripe
                                        setError(
                                          "billingInfo.paymentInfo.creditCard.cardNumber",
                                          {
                                            type: "manual",
                                            message:
                                              e.error.message ||
                                              "Invalid Card Number",
                                          }
                                        );
                                      } else {
                                        // Incomplete card number
                                        setError(
                                          "billingInfo.paymentInfo.creditCard.cardNumber",
                                          {
                                            type: "manual",
                                            message:
                                              "Card Number is incomplete",
                                          }
                                        );
                                      }
                                    }}
                                    options={{ style: inputStyle }} // Customize inputStyle as needed
                                  />
                                  {errors?.billingInfo?.paymentInfo?.creditCard
                                    ?.cardNumber && (
                                    <CFormFeedback
                                      className="d-block"
                                      invalid={true}
                                    >
                                      {
                                        errors?.billingInfo?.paymentInfo
                                          ?.creditCard?.cardNumber?.message
                                      }
                                    </CFormFeedback>
                                  )}
                                </>
                              )}
                            />
                          </CCol>
                        </CRow>
                        <CRow className="mt-3">
                          <CCol>
                            <CFormLabel>Expiration Date</CFormLabel>
                            <Controller
                              name="billingInfo?.paymentInfo?.creditCard?.expirationDate"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <CardExpiryElement
                                    {...field}
                                    onChange={(e) => {
                                      // field.onChange(e);
                                      // trigger(
                                      //   "billingInfo?.paymentInfo?.creditCard?.expirationDate"
                                      // );
                                      if (e.complete) {
                                        // Expiration Date is valid and complete
                                        clearErrors(
                                          "billingInfo.paymentInfo.creditCard.expirationDate"
                                        );
                                        field.onChange(e.value); // Update the field value with the complete expiration date
                                      } else if (e.error) {
                                        // Handle validation errors from Stripe
                                        setError(
                                          "billingInfo.paymentInfo.creditCard.expirationDate",
                                          {
                                            type: "manual",
                                            message:
                                              e.error.message ||
                                              "Invalid Expiration Date",
                                          }
                                        );
                                      } else {
                                        // Incomplete expiration date
                                        setError(
                                          "billingInfo.paymentInfo.creditCard.expirationDate",
                                          {
                                            type: "manual",
                                            message:
                                              "Expiration Date is incomplete",
                                          }
                                        );
                                      }
                                    }}
                                    options={{ style: inputStyle }}
                                  />
                                  {errors?.billingInfo?.paymentInfo?.creditCard
                                    ?.expirationDate && (
                                    <CFormFeedback
                                      className="d-block"
                                      invalid={true}
                                    >
                                      {
                                        errors?.billingInfo?.paymentInfo
                                          ?.creditCard?.expirationDate?.message
                                      }
                                    </CFormFeedback>
                                  )}
                                </>
                              )}
                            />
                          </CCol>
                          <CCol>
                            <CFormLabel>CVV</CFormLabel>
                            <Controller
                              name="billingInfo?.paymentInfo?.creditCard?.cvv"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <CardCvcElement
                                    {...field}
                                    onChange={(e) => {
                                      // field.onChange(e);
                                      // handleFieldChange(
                                      //   "billingInfo?.paymentInfo?.creditCard?.cvv"
                                      // );
                                      if (e.complete) {
                                        // CVV is valid and complete
                                        clearErrors(
                                          "billingInfo.paymentInfo.creditCard.cvv"
                                        );
                                        field.onChange(e.value); // Update the field value with the complete CVV
                                      } else if (e.error) {
                                        // Handle validation errors from Stripe
                                        setError(
                                          "billingInfo.paymentInfo.creditCard.cvv",
                                          {
                                            type: "manual",
                                            message:
                                              e.error.message || "Invalid CVV",
                                          }
                                        );
                                      } else {
                                        // Incomplete CVV
                                        setError(
                                          "billingInfo.paymentInfo.creditCard.cvv",
                                          {
                                            type: "manual",
                                            message: "CVV is incomplete",
                                          }
                                        );
                                      }
                                    }}
                                    options={{ style: inputStyle }}
                                  />
                                  {errors?.billingInfo?.paymentInfo?.creditCard
                                    ?.cvv && (
                                    <CFormFeedback
                                      className="d-block"
                                      invalid={true}
                                    >
                                      {
                                        errors?.billingInfo?.paymentInfo
                                          ?.creditCard?.cvv?.message
                                      }
                                    </CFormFeedback>
                                  )}
                                </>
                              )}
                            />
                          </CCol>
                        </CRow>
                      </>
                    )}
                    {watch("billingInfo.paymentInfo.paymentMethod") ==
                      "Bank" && (
                      <>
                        <CRow className="mt-3">
                          <CCol>
                            <CFormLabel>Account Type</CFormLabel>
                            <div className="d-flex items-center gap-3">
                              {["Checking", "Savings"].map((card) => (
                                <CFormCheck
                                  id={card}
                                  key={card}
                                  value={card}
                                  type="radio"
                                  name="cardType"
                                  label={card}
                                  invalid={
                                    !!errors?.billingInfo?.paymentInfo
                                      ?.bankAccount?.accountType
                                  }
                                  {...register(
                                    `billingInfo.paymentInfo.bankAccount.accountType`,
                                    {
                                      onChange: (e) => {
                                        trigger(
                                          `billingInfo.paymentInfo.bankAccount.accountType`
                                        );
                                      },
                                    }
                                  )}
                                />
                              ))}
                              {errors?.billingInfo?.paymentInfo?.bankAccount
                                ?.accountType && (
                                <CFormFeedback
                                  className="d-block"
                                  invalid={true}
                                >
                                  {
                                    errors?.billingInfo?.paymentInfo
                                      ?.bankAccount?.accountType?.message
                                  }
                                </CFormFeedback>
                              )}
                            </div>
                          </CCol>
                        </CRow>
                        <CRow className="mt-3">
                          <CCol md={6}>
                            <CFormInput
                              label={`Account Name`}
                              placeholder={`Account Name`}
                              autoComplete="text"
                              invalid={
                                !!errors?.billingInfo?.paymentInfo?.bankAccount
                                  ?.accountName
                              }
                              {...register(
                                `billingInfo.paymentInfo.bankAccount.accountName`,
                                {
                                  onChange: (e) => {
                                    trigger(
                                      `billingInfo.paymentInfo.bankAccount.accountName`
                                    );
                                  },
                                }
                              )}
                            />
                            {errors?.billingInfo?.paymentInfo?.bankAccount
                              ?.accountName && (
                              <CFormFeedback className="d-block" invalid={true}>
                                {
                                  errors?.billingInfo?.paymentInfo?.bankAccount
                                    ?.accountName?.message
                                }
                              </CFormFeedback>
                            )}
                          </CCol>
                          <CCol md={6}>
                            <CFormInput
                              label={`Bank Name`}
                              placeholder={`Bank Name`}
                              autoComplete="text"
                              invalid={
                                !!errors?.billingInfo?.paymentInfo?.bankAccount
                                  ?.bankName
                              }
                              {...register(
                                `billingInfo.paymentInfo.bankAccount.bankName`,
                                {
                                  onChange: (e) => {
                                    trigger(
                                      `billingInfo.paymentInfo.bankAccount.bankName`
                                    );
                                  },
                                }
                              )}
                            />
                            {errors?.billingInfo?.paymentInfo?.bankAccount
                              ?.bankName && (
                              <CFormFeedback className="d-block" invalid={true}>
                                {
                                  errors?.billingInfo?.paymentInfo?.bankAccount
                                    ?.bankName?.message
                                }
                              </CFormFeedback>
                            )}
                          </CCol>
                        </CRow>
                        <CRow className="mt-3">
                          <CCol md={6}>
                            <CFormInput
                              label={`Routing Number`}
                              placeholder={`Routing Number`}
                              autoComplete="text"
                              invalid={
                                !!errors?.billingInfo?.paymentInfo?.bankAccount
                                  ?.routingNumber
                              }
                              {...register(
                                `billingInfo.paymentInfo.bankAccount.routingNumber`,
                                {
                                  onChange: (e) => {
                                    trigger(
                                      `billingInfo.paymentInfo.bankAccount.routingNumber`
                                    );
                                  },
                                }
                              )}
                            />
                            {errors?.billingInfo?.paymentInfo?.bankAccount
                              ?.routingNumber && (
                              <CFormFeedback className="d-block" invalid={true}>
                                {
                                  errors?.billingInfo?.paymentInfo?.bankAccount
                                    ?.routingNumber?.message
                                }
                              </CFormFeedback>
                            )}
                          </CCol>
                          <CCol md={6}>
                            <CFormInput
                              label={`Account Number`}
                              placeholder={`Account Number`}
                              autoComplete="text"
                              invalid={
                                !!errors?.billingInfo?.paymentInfo?.bankAccount
                                  ?.accountNumber
                              }
                              {...register(
                                `billingInfo.paymentInfo.bankAccount.accountNumber`,
                                {
                                  onChange: (e) => {
                                    trigger(
                                      `billingInfo.paymentInfo.bankAccount.accountNumber`
                                    );
                                  },
                                }
                              )}
                            />
                            {errors?.billingInfo?.paymentInfo?.bankAccount
                              ?.accountNumber && (
                              <CFormFeedback className="d-block" invalid={true}>
                                {
                                  errors?.billingInfo?.paymentInfo?.bankAccount
                                    ?.accountNumber?.message
                                }
                              </CFormFeedback>
                            )}
                          </CCol>
                        </CRow>
                      </>
                    )}
                  </CRow>
                  <CRow className="mt-3">
                    <CCol>
                      <CFormInput
                        label={`Account Holder Name *`}
                        placeholder={`Account Holder Name`}
                        autoComplete="text"
                        invalid={!!errors?.billingInfo?.accountHolderName}
                        {...register(`billingInfo.accountHolderName`, {
                          onChange: (e) => {
                            trigger(`billingInfo.accountHolderName`);
                          },
                        })}
                      />
                      {errors?.billingInfo?.accountHolderName && (
                        <CFormFeedback className="d-block" invalid={true}>
                          {errors?.billingInfo?.accountHolderName?.message}
                        </CFormFeedback>
                      )}
                    </CCol>
                  </CRow>

                </CRow>

                <CRow className="mt-3">
                  <CCol>
                    <CFormCheck
                      id={"checkedAgreement"}
                      label={
                        "I authorize the International Speeed Horse Association, Inc. to deduct the payment from my account for the amount and indicated above."
                      }
                      name="checkedAgreement"
                      invalid={!!errors?.checkedAgreement}
                      {...register("checkedAgreement", {
                        onChange: (e) => {
                          trigger("checkedAgreement");
                        },
                      })}
                    />
                    {errors?.checkedAgreement && (
                      <CFormFeedback invalid={true}>
                        {errors?.checkedAgreement?.message}
                      </CFormFeedback>
                    )}
                  </CCol>
                </CRow>
                <CRow className="mt-3">
                  <CCol>
                    <CFormLabel className="">
                      Account Holder's Signature *:{" "}
                    </CFormLabel>
                    <CButton
                      color="dark"
                      className="mx-3"
                      onClick={() => setOpenAccountHolderSign(true)}
                    >
                      Open Signature Pad
                    </CButton>
                    <Controller
                      name="billingInfo.accountHolderSignature"
                      control={control}
                      render={({ field }) => (
                        <>
                          {field.value && (
                            <img
                              src={URL.createObjectURL(field.value)}
                              alt="Account Holder Signature"
                              style={{ maxWidth: "100px", maxHeight: "50px" }}
                            />
                          )}
                        </>
                      )}
                    />
                    {openAccountHolderSign && (
                      <CModal
                        visible={openAccountHolderSign}
                        onClose={() => setOpenAccountHolderSign(false)}
                      >
                        <CModalHeader>
                          <CModalTitle>
                            Account Holder's Signature
                          </CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                          <div className="mt-3">
                            <ReactSignatureCanvas
                              ref={accountHolderSignRef}
                              penColor="black"
                              canvasProps={{
                                height: 200,
                                width: 400,
                                style: {
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  backgroundColor: "#f9f9f9",
                                },
                              }}
                            />
                            <CModalFooter>
                              <div className="d-flex gap-3">
                                <CButton
                                  color="secondary"
                                  onClick={() => {
                                    clearSignature(
                                      accountHolderSignRef,
                                      "billingInfo.accountHolderSignature"
                                    );
                                  }}
                                >
                                  Clear
                                </CButton>
                                <CButton
                                  color="secondary"
                                  onClick={() => {
                                    saveSignature(
                                      accountHolderSignRef,
                                      "billingInfo.accountHolderSignature"
                                    );
                                    setOpenAccountHolderSign(false);
                                  }}
                                >
                                  Save
                                </CButton>
                              </div>
                            </CModalFooter>
                          </div>
                        </CModalBody>
                      </CModal>
                    )}
                    {errors?.billingInfo?.accountHolderSignature && (
                      <CFormFeedback className="d-block" invalid={true}>
                        {errors?.billingInfo?.accountHolderSignature?.message}
                      </CFormFeedback>
                    )}
                    {openCheckoutPage && (
                      <CModal 
                        visible={openCheckoutPage} 
                        onClose={() => setCheckoutPage(false)}
                        size="lg"
                        ignoreBackdropClick
                        backdrop="static"
                      >
                        <CModalHeader>
                          <CModalTitle>Payment Details</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                          <div className="p-3">
                            {/* Summary of Fees */}
                            <div className="mb-4">
                              <h5>Order Summary</h5>
                              <div className="border rounded p-1">
                                <div className="d-flex justify-content-between">
                                  <span>Registration Fee:</span>
                                  <span>${30}</span>
                                </div>
                                <hr/>
                                <div className="d-flex justify-content-between">
                                  <span>Verification Fee:</span>
                                  <span> {watch("performanceVerification") === "true" ? "$20" : "-"}</span>
                                </div>
                                <hr/>
                                <div className="d-flex justify-content-between">
                                  <span>Prepay DNA Fee Fee:</span>
                                  <span>{watch("fees.prepayDNAFee") ? "$50" :"-"}</span>
                                </div>
                                <div className="d-flex justify-content-between font-weight-bold border-top pt-2 mt-2">
                                  <span>Total Amount:</span>
                                  <span>$
                                    {(
                                      30 + 
                                      (watch("performanceVerification") === "true" ? 20 : 0) + 
                                      (watch("fees.prepayDNAFee") ? 50 : 0)
                                    ).toFixed(2)}
                                    </span>
                                </div>
                              </div>
                            </div>
                           
                          </div>
                        </CModalBody>
                        <CModalFooter>
                          <div className="d-flex gap-3">
                            {/* <CButton
                              color="secondary"
                              onClick={()=> setCheckoutPage(false)}
                            >
                              Cancel
                            </CButton> */}
                            <CButton
                              color="primary"
                              onClick={handleSubmit(onFormSubmit)}
                            >
                              Proceed
                            </CButton>
                          </div>
                        </CModalFooter>
                      </CModal>
                    )}
                  </CCol>
                </CRow>
              </>
            )}
            {mode === "view" && (
              <>
                <CFormLabel className="mt-3">Payment Info:</CFormLabel>
                <hr />
                <CRow>
                  <CCol sm={5}>
                    <h6 className="mb-0">Payment Mode</h6>
                  </CCol>
                  <CCol sm={6} className="text-secondary">
                    {modalData?.billingInfo?.paymentInfo?.paymentMethod
                      ? modalData?.billingInfo?.paymentInfo?.paymentMethod
                      : "-"}
                  </CCol>
                </CRow>
                <hr />
                <CRow>
                  <CCol sm={5}>
                    <h6 className="mb-0">Last updated at</h6>
                  </CCol>
                  <CCol sm={6} className="text-secondary">
                    {moment(modalData.updatedAt).format("YYYY/MM/DD")}
                  </CCol>
                </CRow>
              </>
            )}
          </CForm>
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={isSubmitted} color="secondary" onClick={onModalClose}>
          Close
        </CButton>
        {
          mode !== 'view' &&
          <CButton
          color="success"
          type="submit"
          className="text-light"
          onClick={handleSubmit(onFormSubmit)}
          disabled={isSubmitted}
        >
          Save
        </CButton>
        }
      </CModalFooter>
    </CModal>
  );
};

export default HorseRegister;
