export const constants = {
  baseUrl: "https://api.registry.horse/api/",/* temp baseUrl ... It should be in .env file*/
  downloadBaseUrl: "https://api.registry.horse/",/* temp baseUrl ... It should be in .env file*/
  imgBaseUrl: "https://api.registry.horse/uploads/",
  accessToken: "accessToken",
  userRole: "userRole",
  userData: "userData",
  endPoints: {
    adminEndPoints: {
      adminLogin: "auth/admin-login",
      createOwner: "admin/members",
      editOwner: "admin/members",
      deleteOwner: "admin/members",
      ownersList: "admin/members/search",
      adminCreateHorse: "admin/horses",
      adminEditHorse: "admin/horses",
      adminDeleteHorse: "admin/horses",
      horseList: "admin/horses/search",
      horseListPending:"admin/horses/search?approvalStatus=pending",
      adminApproveRejectHorse:"admin/approve-horse",
      statistics: "admin/statistics",
      horseRequestsList: "admin/pending-horses",
      memberActiveDeactivate: "admin/member",
      changeOwner: "admin/transfer-horse-ownership",
      sendCredential: "admin/send-membercredentials",
      // for changing the password
      adminOtpPassword: "admin/sendotp-password",

      adminSendOtp: 'admin/verifyOtp-updatepassword',
      adminChangePassword: "admin/change-adminPassword",
      // for changing the the email address

      adminEmailOtpSend: "admin/sendotp",
      adminChangeEmail: '/admin/verifyOtp-updateEmail',
      updatedashboardcontent: '/admin/dashboard-content', //this is put request for update
      getdashboardcontent: 'admin/dashboard/content' // this is get content for the admin

    },
    ownerEndPoints: {
      ownerLogin: "auth/login",
      otpLogin: "members/sendotp",
      horseList: "auth/horses",
      horseListPending:"auth/horses/approvalStatus=pending",
      searchHorse: "auth/horses/search",
      ownerCreateHorse: "/horses",
      ownerDeleteHorse: "members/horses",
      ownerEditHorse:"members/horses",
      horseRequestsList: "auth/pending-horses",
      horseOwnerCreated: "auth/horses/register",
      editOwner: "members/profile", //this end points for the updating profile image
      // for changing the password
      ownerOtpPassword: "members/sendotp-password",

      ownerSendOtp: 'members/verifyOtp-updatepassword',
      ownerChangePassword: "members/change-memberPassword",
      ownerdashboardcontent: "members/dashboard/content",

      // new end points for the change password and fields
      ownerChangeProfilePassword: "members/change-password",
      ownerChangeProfileFields: "members",
      ownerGetProfileFields:"members",
      ownerRegisterHorse:"members/horses",
      ownerRegisterPayment:"members/payment-process",
      ownerGetPaymentStatus:"members/stripe/payment-status?paymentIntentId="
    },
    socket:{
      socketUrl: 'members/webhook-stripe',
    }
  },
};
