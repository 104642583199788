import 'core-js'
import { Suspense } from 'react'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import App from './App'
import store from './redux/store'
import reportWebVitals from './reportWebVitals'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <HashRouter> 
      <Suspense fallback={loading}>
        <App />
      </Suspense>
    </HashRouter>
  </Provider>

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
