import { lazy } from "react";
import { Navigate } from "react-router-dom";
import HorseRegister from "src/views/forms/HorseRegister";
import OwnerForgetPassword from "src/views/pages/login/OwnerForgetPassword";
const OwnerSearchHorse = lazy(() => import("src/views/horse-list/OwnerSearchHorse"));
// const Dashboard = lazy(() => import("src/views/dashboard/Dashboard"));
const OwnerLogin = lazy(() => import("src/views/pages/login/OwnerLogin"));
const HorseList = lazy(() => import("src/views/horse-list/HorseList"));
const OwnerProfile = lazy(() =>
  import("src/views/pages/owner-profile/OwnerProfile")
);
const OwnerDashboard = lazy(() =>
  import("src/views/dashboard/OwnerDashboard")
);
const FormsAndRegistration = lazy(() => import("src/views/forms/FormsAndRegistration"));

export const ownerRoutes = [
  {
    path: "/login",
    name: "Login",
    element: <OwnerLogin />,
    authPage: true,
  },
  {
    path: "/horse-list",
    name: "Horse List",
    element: <HorseList />,
    privatePage: true,
  },
  {
    path: "/profile",
    name: "Profile",
    element: <OwnerProfile />,
    privatePage: true,
  },
  {
    path: "/search-all-horses",
    name: "Search Horses",
    element: <OwnerSearchHorse />,
    privatePage: true,
  },
  {
    path: "/register-horse",
    name: "Horse Registration",
    // element: <HorseRegister />,
    element: <FormsAndRegistration />,
    privatePage: true,
  },
  {
    path: "/owner/forget-password",
    name: "Forget Password",
    element: <OwnerForgetPassword/>,
    authPage: true,
  },
  {
    path: '/dashboard',
    name: "Dashboard",
    element: <OwnerDashboard/>,
    privatePage: true,
  }
].map((data) => ({ ...data, ownerRoute: true }));
