import Router from "./routes/Router";
import "./scss/style.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
console.log('STRIPE_PUBLIC_KEY',process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
// Containers
const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <Router />
    </Elements>
  );
};

export default App;
